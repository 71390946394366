<div class="container-fluid">


  <!-- checking permissions -->

  <div class="col-md-12"
       *ngIf="!loading && !profile.canOr([profile.getPermissionName(entityName, 'show'), profile.getPermissionName(entityName, 'update')])">
    <p class="lead">Not authorized to change or see this object</p>
  </div>

  <!-- show non locked company report section after data has been loaded -->
  <div *ngIf="profile.canOr([profile.getPermissionName(entityName, 'show'), profile.getPermissionName(entityName, 'update')])">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="check"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Check</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-body">
          <div>
             <h5 class="card-title half-width">Filter Report</h5>
             <con-icon-by-name iconName="angle-right" (click)="filterExpanded = !filterExpanded" [mutations]="filterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']" class="filter-toggle"></con-icon-by-name>
          </div>
          <div [ngClass]="filterExpanded ? 'd-block' : 'd-none'">
            <con-filter-report (filterUpdate)="updateFilterConfiguration($event)"></con-filter-report>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- preloader -->

  <con-loader *ngIf="loading" [showCircularLoder]=loading></con-loader>

  <con-no-data [count]="checkCompanyReports.length" message="No company reports found" *ngIf="!loading"></con-no-data>

  <div class="inner-scroll" *ngIf="checkCompanyReports && !loading &&
  profile.canOr([profile.getPermissionName(entityName, 'show'), profile.getPermissionName(entityName, 'update')])">

    <!--     loop non locked reports -->

    <div *ngFor="let report of checkCompanyReports">
      <div class="card mb-2" [ngClass]="report?.is_fully_annotated !== null && !report?.is_fully_annotated ? 'annotation-error': ''">
        <div class="card-header cursor-pointer" (click)="toggleExpansion(report)">
          <div class="d-flex justify-content-start">
            <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
            <span>
              {{report?.company?.name}}
              <span *ngIf="report?.company?.name && (report?.report_type || report?.report_date)">/ </span>{{report?.report_type | titlecase}}
              <span *ngIf="report?.report_date && report?.report_type">, </span>
              {{report?.report_date | date}}
            </span>
            <span *ngIf="report?.company?.website" ngbTooltip="Website Link">
              <a [href]=report?.company?.website class="text-white pl-10" target="_blank"> <con-icon-by-name iconName="external-link"></con-icon-by-name></a>
            </span>
            <div>
            <span class="badge badge-pill timeline-pill badge-primary ml-status-created ml-2" [ngbTooltip]=MLSendStatus.CREATED_MESSAGE *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.CREATED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>CREATED</span>
            <span class="badge badge-pill timeline-pill badge-primary ml-status-in-progress ml-2" [ngbTooltip]=MLSendStatus.IN_PROGRESS_MESSAGE *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.IN_PROGRESS"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>IN PROGRESS</span>
            <span class="badge badge-pill timeline-pill badge-primary ml-status-submitted ml-2" [ngbTooltip]=MLSendStatus.SUBMITTED_MESSAGE *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.SUBMITTED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>SUBMITTED</span>
            <span class="badge badge-pill timeline-pill badge-primary ml-status-failed ml-2" [ngbTooltip]=MLSendStatus.FAILED_MESSAGE *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.FAILED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>FAILED</span>
            <span class="badge badge-pill timeline-pill badge-primary ml-status-closed ml-2" [ngbTooltip]=MLSendStatus.CLOSED_MESSAGE *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.CLOSED"> <con-icon-by-name [mutations]="['ml-status-black']" iconName="check"></con-icon-by-name> CLOSED</span>
              <span class="badge badge-pill timeline-pill badge-primary ml-annotation-failed ml-2"
                    ngbTooltip="Problem with the labeling of the report"
                    *ngIf="report?.is_fully_annotated !== null && !report?.is_fully_annotated"
              >
              <con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>
                NOT FULLY ANNOTATED
            </span>
            </div>
            <div class="card-header-toggle ml-auto">
              <con-icon-by-name iconName="angle-right"
                                [mutations]="report?.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
            </div>
          </div>
        </div>
        <div class="card-body" [hidden]="!report?.expanded">
          <con-entity-form-content [entityName]=entityName [canDelete]="false" [entity]="report"
                                   [showWhenChanged]="false"
                                   [redirectButton]="{show: true, label: 'Go to Report', link: '../' + report.company_id + '/report/'+report.id+'/report-overview'}"
                                   (afterSave)="companyReportSaved($event)"></con-entity-form-content>
        </div>
      </div>
    </div>

    <!-- pagination -->

    <div class="d-flex justify-content-center mt-2"
         [ngClass]="{'hide-pagination':!paginatedCheckCompanyReports?.total}">

      <ngb-pagination [collectionSize]="paginatedCheckCompanyReports?.total"
                      [page]="paginatedCheckCompanyReports?.current_page"
                      (pageChange)="loadCheckCompanyReports($event)"
                      [pageSize]="paginatedCheckCompanyReports?.per_page"
                      maxSize="10"
                      [rotate]="true">
      </ngb-pagination>

    </div>


  </div>

</div>
