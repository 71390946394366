import { Component, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { EntitiesService, EntityDescription } from '../../services/entities.service';
import { EntityConfirmModalComponent } from '../../components/views/entity-confirm-modal.component';
import { RelationService, Relation } from '../../services/relation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../../doc-process/sub-modules/doc-process-common/services/util.service';
import { Subject } from 'rxjs';
import { TranslationService } from 'app/utility/services/translation.service';
  import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'con-to-many-editor',
    templateUrl: './to-many-editor.component.html'
})

export class ToManyEditorComponent implements OnChanges {
    @Input() relation: any;
    @Input() customCompanyReportFileView: boolean = false;
    @Input() entity: any;
    @Input() canShow = true;
    @Input() canEdit = true;
    @Input() showEdit = false;
    @Input() fromReports = false;
    @Input() parentCompany: any;
    @Input() isEmployeeFigures = false;
    @ViewChild('ReportEdit') EditWindow: ElementRef;
    public changes$: Subject<any> = new Subject<any>();

    public relatedEntityDescriptions: EntityDescription;
    public inverseRelation: any;
    public fixedSearchParams: any;
    public fixedChildValues: any;

    public workers: any = [];
    public loaders: any = {};
    public relations: Relation;
    public errors: any;

    public pivotFormObject: any;
    public editEntity: any;
    public reloadEditData = true;
    public isLocked = false;
    public isCalculated = false;
    public relationData: any;
    previewMLReportUrl = '';
    private previousActionsReturnValue: any = {};
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(public service: EntitiesService,
                private relationService: RelationService,
                private modalService: NgbModal,
                private toaster: ToastrService,
                private translationService: TranslationService) {}

    ngOnChanges() {
        this.relations = this.relationService.getRelation(this.relation);
        if (this.entity && (this.service.isEntityLocked(this.entity) || this.service.entityLockedByMl(this.entity))) {
            this.isLocked = true;
            this.canEdit = false;
        }
        if (this.entity && this.service.isEntityCalculated(this.entity)) {
            this.isCalculated = true;
            this.canEdit = false;
        }
        this.relations.init()
            .subscribe(() => {
                this.relatedEntityDescriptions = this.relations.getAllRelatedEntityDescriptions();
                this.inverseRelation = this.relations.getAllInverseRelations()[0];
                this.fixedSearchParams = this.relations.getAllFixedSearchParams(this.entity);
                this.fixedChildValues = this.relations.getAllFixedChildValues(this.entity);
            });
    }

    changeInList($event) {
        if ($event.action === 'detach') {
          this.removeFromRelation($event.entity);
        }
        if ($event.action === 'delete') {
            this.confirmDeleteEntity($event.entity);
        }
        if ($event.action === 'edit' && !this.showEdit) {
            this.showInPivotForm($event.entity);
        }
        if ($event.action === 'edit' && this.showEdit) {
            this.service.companyReportFileEditingSubject.next('true');
            this.editEntity = $event.entity;
            this.EditWindow.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    resetPivotForm() {
        this.showInPivotForm(undefined);
    }

    showInPivotForm(entity: any) {
        this.pivotFormObject = entity;
    }

    confirmDeleteEntity(entity: any) {
        const modalRef = this.modalService.open(EntityConfirmModalComponent,
            {
                size: 'md'
            });

          const data = {
            title: this.relation.label,
            message: 'Are you sure you want to delete ?',
            alert: false
          }

          modalRef.componentInstance.modalData = data;
          modalRef.result.then((result) => {
            this.deleteEntity(entity);
          }, (reason) => {
          });
    }

    deleteEntity(entity: any) {
        this.loaders[entity.id] = true;
        this.service.deleteEntity(this.inverseRelation.owner, entity)
            .subscribe(res => {
                const event = { action: 'removed', objects: [res] };
                this.onChange.emit(event);
                this.changes$.next(event);
                this.loaders[entity.id] = false;
            },
                err => {
                    if(err.hasOwnProperty('type')){
                      if(err.type === "LOCKED_ERROR" && !this.entity.locked){
                        this.toaster.warning('ML data is being processed', 'ML Error');
                      }
                    }
                    this.loaders[entity.id] = false;
                })
    }

    removeFromRelation(entity: any) {
        this.loaders[entity.id] = true;
        this.service.removeRelation(this.relation.owner, this.entity.id, this.relation.name, [entity.id])
                    .subscribe(res => {
                        const event = { action: 'removed', objects: [res], entity: this.entity };
                        this.onChange.emit(event);
                        this.changes$.next(event);
                        this.loaders[entity.id] = false;
                    },
                    err => {
                        this.loaders[entity.id] = false;
                        this.errors = err.getData();
                        if(err.hasOwnProperty('type')){
                          if(err.type === "LOCKED_ERROR" && !this.entity.locked){
                            this.toaster.warning('ML data is being processed', 'ML Error');
                          } else {
                            this.toaster.warning(err.data.message);
                          }
                        }
                    });
    }

    appendToRelation(relation, $event) {
        const event = { action: 'added', objects: [$event] };
        this.onChange.emit(event);
        this.changes$.next(event);
        this.editEntity = null;
        if($event.description) {
         this.translationService.onDescriptionSaved($event);
        }
    }

    createWorker(relation, $event) {
        const worker = {
            entity: $event.entity,
            pivot: $event.pivot,
            type: relation.owner,
            id: $event.entity.id,
            loading: true
        }

        this.workers.push(worker);

        return worker;
    }

    removeWorker(worker) {
        worker.loading = false;
        const indx = this.workers.findIndex(w => w.id === worker.id);
        this.workers.splice(indx, 1);
    }

    getUpdatePivotObserver($event) {
        return this.service.updateRelation(
            this.relation.owner,
            this.entity.id,
            this.relation.name,
            $event.entity.id,
            $event.pivot);
    }

    updatePivotData(relation, $event) {
        const worker = this.createWorker(relation, $event);
        this.errors = {};

        this.getUpdatePivotObserver($event)
            .subscribe(res => {
                this.removeWorker(worker);

                const event = { action: 'updated', objects: [res] };
                this.onChange.emit(event);
                this.changes$.next(event);
                this.showInPivotForm(undefined);

            }, err => {
                if(err.hasOwnProperty('type')){
                  if(err.type === "LOCKED_ERROR" && !this.entity.locked){
                    this.toaster.warning('ML data is being processed', 'ML Error');
                  }
                }
                this.removeWorker(worker);

                this.errors = err.getData();
                this.showInPivotForm(worker.entity);
            });
    }

    addToRelation(relation, $event) {
        const worker = this.createWorker(relation, $event);
        this.errors = {};

        this.getSaveObserver($event)
            .subscribe(res => {
                this.removeWorker(worker);
                const event = { action: 'added', objects: [res] };
                this.onChange.emit(event);
                this.changes$.next(event);
                this.showInPivotForm(undefined);
            }, err => {
                if(err.hasOwnProperty('type')){
                  if(err.type === "LOCKED_ERROR" && !this.entity.locked){
                    this.toaster.warning('ML data is being processed', 'ML Error');
                  }
                }
                this.removeWorker(worker);
                this.errors = err.getData();

                if (this.relations.isHasManyToManyRelation()) {
                    if (this.relations.hasPivotFields()) {
                        worker.entity.pivot = $event.pivot;
                        this.showInPivotForm(worker.entity);
                    } else {
                        this.showInPivotForm(undefined);
                    }
                }
            });
    }
    getSaveObserver($event) {
        if ($event.pivot) {
            return this.service.addRelation(
                this.relation.owner,
                this.entity.id,
                this.relation.name,
                [$event.entity.id],
                $event.pivot
            );
        }
        return this.service.addRelation(
            this.relation.owner,
            this.entity.id,
            this.relation.name,
            [$event.entity.id]
        );
    }
    getActions() {
        let currentActionsReturnValue = [];
        if (this.canEdit && !this.relation.disabled) {
            const actions = [];
            if (!this.inverseRelation.required) {
                actions.push({
                    label: 'Exclude',
                    icon: 'minus-circle',
                    buttonClass: 'danger',
                    name: 'detach'
                });
                if (this.relations.isHasManyToManyRelation()
                    && this.inverseRelation.pivot_fields.length) {
                    actions.push({
                        label: 'Edit',
                        icon: 'pencil',
                        buttonClass: 'warning',
                        name: 'edit'
                    });
                }
            } else {
                actions.push({
                    label: 'Delete',
                    icon: 'remove',
                    buttonClass: 'danger',
                    name: 'delete'
                });
                if (this.showEdit) {
                    actions.push({
                        label: 'Edit',
                        icon: 'pencil',
                        buttonClass: 'warning',
                        name: 'edit'
                    });
                }
            }

          currentActionsReturnValue = actions;
        } else {
          currentActionsReturnValue = [];
        }
      if (!UtilService.areObjectsEqual(currentActionsReturnValue, this.previousActionsReturnValue)) {
        this.previousActionsReturnValue = currentActionsReturnValue;
      }
      return this.previousActionsReturnValue;
    }
    closeEdit() {
        this.editEntity = null;
    }
    getCompany() {
        if (this.entity && this.entity.company && this.entity.company.id) {
            return this.entity.company;
        } else {
            return null;
        }
    }
    setResponseListData(response) {
        this.relationData = response;
    }
    hasEmployee() {
        if (this.inverseRelation.owner === 'CompanyEmployee' && this.fromReports) {
            if (this.relationData && this.relationData.data && this.relationData.data.length > 0) {
                return true;
            }
            return false;
        }
        return false;
    }

  previewUrl(data: any) {
    this.previewMLReportUrl = data+ '#toolbar=0&navpanes=0&scrollbar=0';
  }
  setPreviewMLReportUrlEmpty(closeEvent: boolean) {
    if(closeEvent) {
      this.previewMLReportUrl = null;
    }
  }
}
