import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { EntityDescription, EntitiesService } from '../../services/entities.service';


@Component({
    selector: 'con-entity-form',
    templateUrl: './entity-form.component.html'
})

export class EntityFormComponent implements OnChanges {
    @Input() entityName: string;
    @Input() entity: any = {};
    @Input() fixedValues: any = {};
    @Input() removeRelations = false;
    @Input() isExpanded = true;
    @Input() canEdit: boolean;
    @Input() canShow: boolean;
    @Input() canStore: boolean;
    @Input() canDelete: boolean;
    @Input() emptyAfterSave = false;
    @Input() except: any = [];
    @Input() only: any = [];
    @Input() showWhenChanged = true;
    @Input() fromCalendar = false;
    @Input() reloadEditData: boolean;
    @Input() fromDetailPage: boolean;
    @Input() parentCompany: any;
    @Input() fromReports = false;
    @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();

    public entityDescription: EntityDescription;
    public expanded: boolean;
    public obj: any;
    public isLocked = false;
    public isCalculated = false;
    constructor(private service: EntitiesService) { }

    isLoading() {
        return this.service.isLoading();
    }

    toggleExpansion() {
        this.expanded = !this.expanded;
    }

    entityAlreadyExists() {
        return this.obj && this.obj.id !== undefined;
    }

    ngOnChanges() {
        if (!this.entity) {
            this.obj = {};
        } else {
            this.obj = this.entity;
            if (this.service.isEntityLocked(this.entity, this.entityName)) {
                this.isLocked = true;
            }
            if (this.service.isEntityCalculated(this.entity, this.entityName)) {
                this.isCalculated = true;
            }
        }
        this.expanded = this.isExpanded;
        this.service.getEntityDescriptionByEntityName(this.entityName)
            .subscribe(entityDescription => {
                this.entityDescription = entityDescription;
            });
    }

    onSave(entity) {
        this.afterSave.emit(entity);
    }
}
