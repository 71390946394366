
<con-loader *ngIf="loading"></con-loader>
<div *ngIf="reportButtons" class="input-group input-group-search" [ngClass]="{'active':search}">
  <div class="input-group-prepend" (click)="focusInput();" [ngbTooltip]="search ? 'Close Search' : 'Search'" placement="right" container="body">
    <span class="input-group-text"><con-icon-by-name iconName="search"></con-icon-by-name></span>
  </div>
  <input *ngIf="search" #search autofocus type="text" class="form-control" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput" (ngModelChange)="searchReport($event)">
</div>
<nav class="nav entity-list">
  <ng-container *ngFor="let report of reportButtons">
    <div (click)="goToReport(report)">
      <a class="nav-link side-menu-link"
         [href]="getReportLink(report)"
         (click)="$event.preventDefault()">
        {{report.report_type}}, {{report.report_date | date:'yyyy-MM-dd'}}
      </a>
    </div>
  </ng-container>
</nav>
