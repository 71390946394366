<div *ngIf="!hasFile()">
    <div *ngIf="!uploader.queue.length">
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="file" ng2FileSelect [uploader]="uploader">
            <label class="custom-file-label" for="file"></label>
        </div>
    </div>
    <div *ngIf="uploader.queue.length">
        <strong>{{ uploader.queue[0].file?.name }}</strong>
        <div *ngIf="uploader.options.isHTML5 && uploader.queue[0].progress < 100">'
            {{ uploader.queue[0].file?.size/1024/1024 | number:'.2' }} MB
        </div>
        <div *ngIf="uploader.options.isHTML5 && uploader.queue[0].progress < 100">
            <div class="progress" style="margin-bottom: 0;">
                 <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.queue[0].progress + '%' }"></div>
             </div>
        </div>
    </div>
</div>
<div *ngIf="hasFile()">
    <div *ngIf="hasSubmitted">
        <strong>{{ uploader.queue[0].file?.name }}</strong> <con-icon-by-name iconName="remove" (click)="emptyFile()"></con-icon-by-name>
    </div>
    <div *ngIf="!hasSubmitted">
        <strong>{{ control.value }}</strong> <con-icon-by-name iconName="remove" (click)="emptyFile()"></con-icon-by-name>
    </div>
    <img *ngIf="isImage()" class="img-thumbnail" width="100%" [conResolveLinkToFile]="control.value" fileType="image">
    <a target="_blank" *ngIf="!isImage()" type="link" [conResolveLinkToFile]="control.value">Open</a>
</div>
