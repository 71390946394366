import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { StaticSettings } from '../../settings.class';

import { Observable ,  ReplaySubject } from 'rxjs';

@Injectable()
export class CountService {
    private countsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
    private countRequest: Observable<any>;

    private loading = false;
    private counts: any;
    public context = '';

    constructor(private http: HttpClient) {
        this.getCounts().subscribe(counts => {
            this.counts = counts;
        });
    }

    public decrementCount(type: string) {
        this.counts[type]--;
        this.countsSubject.next(this.counts);
    }

    public setCount(type: string, count: number) {
        this.context = type;
        if (this.counts && this.counts[type] !== count) {
            this.counts[type] = count;
            this.countsSubject.next(this.counts);
        }
    }

    getCounts(refresh: boolean = false): Observable<any> {
        if (!this.countRequest || refresh) {
            this.loading = true;
            this.countRequest = this.http.get(StaticSettings.BASE_URL + '/' + StaticSettings.COUNTS_ENDPOINT);

            this.countRequest.subscribe(res => {
                                            this.countsSubject.next(res);
                                            this.loading = false;
                                        },
                                        err => {
                                            this.countsSubject.error(err);
                                            this.loading = false;
                                        })
        }
        return this.countsSubject.asObservable();
    }
    isLoading() {
        return this.loading;
    }
    setIsLoading(value: boolean): void {
      this.loading = value;
    }
}
