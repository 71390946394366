<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Company Reports</h3>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!loading && company">
        <div class="col-12">
            <div class="d-flex justify-content-start sticky-header non-sticky py-2">
              <h3 class="mb-0">
                <a routerLink="../../../entity/company/{{company?.id}}"><span class="badge badge-dark">{{company.name}}</span></a>
                <span *ngIf="companyWebsite"><a [href]=companyWebsite class="link-fontsize" target="_blank">{{getStripedWebsiteString(companyWebsite)}}</a></span>
              </h3>
                <div class="chip chip-inverse ml-auto">
                    <con-avatar [userId]="company.manager?.id" [size]="32"></con-avatar>
                    {{company.manager?.first_name}} {{company.manager?.last_name}}
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-header">
                    <con-icon-by-name iconName="paperclip" [mutations]="['fw']"></con-icon-by-name> New company report
                </div>
                <div class="card-body">
                    <con-entity-form-content entityName="CompanyReport" [showWhenChanged]="false"
                        [entity]="entityCompany" [preFillValues]="preFillValues"
                        [fixedValues]="{company_id: company.id}" [canDelete]="false"
                        (afterSave)="newReportSaved($event)" toastMessage="New report saved successfully!">
                    </con-entity-form-content>
                </div>
            </div>
        </div>
    </div>
    <con-loader *ngIf="loading"></con-loader>
    <div *ngIf="!loading">
        <div *ngFor="let report of company?.company_reports; let index = index">
            <div>
                <div class="card mb-2">
                    <div class="card-header" (click)="toggleType(report)">
                        <div class="d-flex justify-content-start align-items-center">
                            <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                            <span type="button" (click)="goToReportPage($event, report)">{{getDisplayName(report)}}</span>
                            <span class="badge badge-pill timeline-pill badge-primary ml-status-created ml-2" *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.CREATED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>{{MLSendStatus.CREATED_MESSAGE}}</span>
                            <span class="badge badge-pill timeline-pill badge-primary ml-status-in-progress ml-2" *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.IN_PROGRESS"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>{{MLSendStatus.IN_PROGRESS_MESSAGE}}</span>
                            <span class="badge badge-pill timeline-pill badge-primary ml-status-submitted ml-2" *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.SUBMITTED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>{{MLSendStatus.SUBMITTED_MESSAGE}}</span>
                            <span class="badge badge-pill timeline-pill badge-primary ml-status-failed ml-2" *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.FAILED"><con-icon-by-name [mutations]="['ml-status-black']" iconName="gear"></con-icon-by-name>{{MLSendStatus.FAILED_MESSAGE}}</span>
                            <span class="badge badge-pill timeline-pill badge-primary ml-status-closed ml-2" *ngIf="report?.ml_info?.ml_request_status === MLSendStatus.CLOSED"> <con-icon-by-name [mutations]="['ml-status-black']" iconName="check"></con-icon-by-name> {{MLSendStatus.CLOSED_MESSAGE}}</span>
                          <div class="card-header-toggle-individual ml-auto">
                            <a [href]='report?.task_backlink' target='_blank' *ngIf='report?.task_backlink'>
                              <div class="chip chip-inverse">
                                <con-icon-by-name iconName="external-link"></con-icon-by-name>
                                Task
                              </div>
                            </a>
                                <button class="btn btn-sm btn-primary btn-padding" (click)="openSendToMLPopup($event, report)">Send To ML <span class="spinner-border spinner-border-sm" *ngIf="showMlLoader"></span></button>
                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(report)">
                                </con-icon-by-name>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 col-6" *ngIf="!report?.hide">
                        <con-entity-form-content [entity]="report" entityName="CompanyReport"
                            [fixedValues]="{ company_id: company?.id }" [showWhenChanged]="false" [canDelete]="false"
                            (afterSave)="reportUpdated($event, index)"
                            toastMessage="Company Report updated successfully!">
                        </con-entity-form-content>
                    </div>

                    <con-loader *ngIf="report?.loading"></con-loader>
                    <div class="card-body taxonomy-td" *ngIf="!report?.hide">
                        <ng-container>
                            <ng-container *ngFor="let relation of entityDescription?.getRelationsOutsideForm()">
                                <div class="row" *ngIf="relation.name === 'taxonomies'">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-header cursor-pointer" role="tab"
                                                (click)="toggleComponent(relation.name)">
                                                <div class="d-flex justify-content-start">
                                                    <con-icon-by-name iconName="object-group" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                                             {{relation.label }}
                                                    <div class="card-header-toggle ml-auto">
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <con-to-many-editor [canShow]="true" [canEdit]="true"
                                                    [relation]="relation" [entity]="report" [showEdit]="true"
                                                    (onChange)="afterRelationChange($event)">
                                                </con-to-many-editor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <con-no-data [count]="company?.company_reports?.length" [message]="noDataMessage">
                </con-no-data>
            </div>
        </div>
    </div>
</div>
