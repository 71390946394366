
import { switchMap, tap, catchError, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';
import { UsersService } from '../../shared/services/users.service';
import { EntitiesService } from '../../entities/services/entities.service';
import { TaskLinkingService } from '../services/task-linking.service';
import { EntityConfirmModalComponent } from '../../entities/components/views/entity-confirm-modal.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription ,  BehaviorSubject, of } from 'rxjs';

import { upperCaseFirst } from 'change-case';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'con-assignment-list',
    templateUrl: './assignment-list.component.html'
})

export class AssignmentListComponent implements OnInit, OnDestroy {
    @Input() type = 'user';
    @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterMarkedAsDone: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterMarkedAsUndone: EventEmitter<any> = new EventEmitter<any>();

    private loading: boolean;
    public modes_loading: boolean;

    private user: any;
    public tasks: any;
    public mode = 'self';
    public modes: string[] = [];
    public statusMode = 'undone';
    public statusModes: any[] = [{
        value: 'all',
        label: 'All'
    }, {
        value: 'done',
        label: 'Done'
    }, {
        value: 'undone',
        label: 'Undone'
    }];
    duplicateShareholderMode = false;
    public page = 1;
    private page$: BehaviorSubject<number> = new BehaviorSubject<number>(this.page);

    private expansionDict: any = {};
    public loaders: any = {};
    public noDataMessage = 'No tasks to display.';
    private tasksSubscription: ISubscription;

    public now: any;

    constructor(public profile: ProfileService,
                private service: EntitiesService,
                private users: UsersService,
                public taskLinking: TaskLinkingService,
                private toastr: ToastrService,
                private modalService: NgbModal) {}

    ngOnInit() {
        this.now = moment();
        this.loading = true;
        this.modes_loading = true;
        this.tasksSubscription = this.profile
                                    .getUser().pipe(
                                    switchMap(user => {
                                        this.user = user;
                                        if (user !== null) {
                                            return this.getModesObserver();
                                        }

                                    }))
                                    .pipe(tap(modes => {
                                        this.modes = this.generateModes(modes);
                                        this.modes_loading = false;
                                    }))
                                    .pipe(switchMap(modes => {
                                        return this.getTaskObserver();
                                    }))
                                    .pipe(catchError(error => {
                                        return of(false);
                                    }))
                                    .subscribe(tasks => {
                                        if (tasks) {
                                            this.tasks = tasks;
                                            this.loading = false;
                                        }
                                    },
                                    err => {
                                        console.log(err);
                                        this.loading = false;
                                    });
    }
    public userOwnsTask(task: any) {
        return this.user && task.owner_id === this.user.id;
    }
    public incrementComments(task: any) {
        task.comments_count++;
    }
    public decrementComments(task: any) {
        task.comments_count--;
    }
    deleteTask(task: any) {
        const modalRef = this.modalService.open(EntityConfirmModalComponent,
            {
                size: 'md'
            });

          const data = {
            title: 'Task',
            message: 'Are you sure you want to delete ?',
            alert: false
          }

          modalRef.componentInstance.modalData = data;
          modalRef.result.then((result) => {
            this.deleteTaskConfirmed(task);
          }, (reason) => {
          });
    }
    public deleteTaskConfirmed(task: any) {
        this.loaders['delete-' + task.id] = true;
        this.service.deleteEntity('Task', task)
                    .subscribe(data => {
                        this.removeTaskFromList(data);
                        this.loaders['delete-' + data.id] = true;
                        this.afterDelete.emit(data);
                        this.toastr.success('Deleted successfully!', 'Task');
                    }, err => {
                        this.loaders['delete-' + task.id] = false;
                    });
    }
    public markAsUndone(task: any) {
        const payload = {
            id: task.id,
            done: false
        };

        this.loaders['done-' + payload.id] = true;
        this.service.saveEntity('Task', payload).subscribe(data => {
                        if (this.statusMode === 'done') {
                            this.removeTaskFromList(data);
                        } else {
                            this.updateTaskInList(data);
                        }
                        this.afterMarkedAsUndone.emit(task);
                        this.loaders['done-' + task.id] = false;
                        this.toastr.success('Marked as undone!', 'Task');
                    }, err => {
                        this.loaders['done-' + task.id] = false;
                        this.toastr.error('Sorry, some error occurred!', 'Task');
                    });
    }
    public markAsDone(task: any) {
        const payload = {
            id: task.id,
            done: true
        };

        this.loaders['done-' + payload.id] = true;
        this.service.saveEntity('Task', payload).subscribe(data => {
                        if (this.statusMode === 'undone') {
                            this.removeTaskFromList(data);
                        } else {
                            this.updateTaskInList(data);
                        }
                        this.afterMarkedAsDone.emit(task);
                        this.loaders['done-' + task.id] = false;
                        this.toastr.success('Marked as done!', 'Task');
                    }, err => {
                        this.loaders['done-' + task.id] = false;
                        this.toastr.error('Sorry, some error occurred!', 'Task');
                    });
    }
    public afterReassign(task: any) {
        const indx = this.tasks.data.findIndex(t => t.id === task.id);
        const old_task = this.tasks.data[indx];
        if ((old_task.assigned_to_id !== task.assigned_to_id
            && this.mode !== 'any')
            || (old_task.assigned_to_type !== task.assigned_to_type
                && this.type !== 'user_owns')
            ) {
            this.removeTaskFromList(task);
        } else {
            this.updateTaskInList(task);
        }
    }
    public updateTaskInList(task: any) {
        const indx = this.tasks.data.findIndex(t => t.id === task.id);
        if (indx > -1) {
            this.tasks.data[indx] = task;
        }
    }
    private removeTaskFromList(task: any) {
        const indx = this.tasks.data.findIndex(t => t.id === task.id);
        if (indx > -1) {
            this.tasks.data.splice(indx, 1);
        }
    }

    private generateModes(modes) {
        const res = [];
        res.push({ value: 'any', label: 'Any' });
        if (this.type === 'user' || this.type === 'user_owns') {
            res.push({ value: 'self', label: 'You' });
        } else if (this.type === 'team') {
            if (this.user.team) {
                res.push({ value: 'self', label: this.user.team.name });
            }
        }
        Object.keys(modes).forEach(key => {
            const mode = modes[key];
            if (this.type === 'user' || this.type === 'user_owns') {
                if (mode.id !== this.user.id) {
                    res.push({ value: mode.id, label: mode.first_name + ' ' + mode.last_name });
                }
            } else if (this.type === 'team') {
                if (mode.id !== this.user.team_id) {
                    res.push({ value: mode.id, label: mode.name });
                }
            }
        });
        return res;
    }
    private getModesObserver() {
        if (this.type === 'user' || this.type === 'user_owns') {
            return this.users.getUsers();
        } else {
            return this.users.getTeams();
        }
    }
    public getOwnerName(task: any) {
        let ownerName = '';
        if (task && task.owner) {
            if (task.owner.first_name) {
                ownerName += task.owner.first_name;
                ownerName += ' ';
            }
            if (task.owner.last_name) {
                ownerName += task.owner.last_name;
            }
        }
        return ownerName;
    }
    private getTaskObserver() {
        const serachParams$ = this.getSearchParamsObserver();
        return serachParams$
                    .pipe(tap(() => {
                        this.loading = true;
                        this.expansionDict = {};
                    }))
                    .pipe(switchMap(searchParams => {
                        return this.service.searchEntities('Task', searchParams.terms, {page: searchParams.page, order_by: searchParams.orderBy,order_asc: searchParams.orderDesc});
                    }));
    }
    private getSearchParamsObserver() {
        return this.page$.pipe(map(page => {
            const searchTerms = {};

            if (this.type === 'user' || this.type === 'team') {
                searchTerms['assigned_to_type'] = upperCaseFirst(this.type)
            }
            if (this.statusMode === 'undone') {
                searchTerms['done'] = false;
            } else if (this.statusMode === 'done') {
                searchTerms['done'] = true;
            }
            if(this.duplicateShareholderMode) {
              searchTerms['internal_name'] = 'duplicate_shareholders';
            }
            const orderBy = 'deadline';
            const orderDesc = true;
            if (this.mode !== 'any') {
                if (this.type === 'user' || this.type === 'team') {
                    searchTerms['assigned_to_id'] = (this.mode === 'self') ? this.getOwnId() : this.mode;
                } else if (this.type === 'user_owns') {
                    searchTerms['owner_id'] = (this.mode === 'self') ? this.getOwnId() : this.mode;
                }
            }
            return { page, orderBy, orderDesc, terms: searchTerms };
        }));
    }
    private getOwnId() {
        return (this.type === 'user' || this.type === 'user_owns') ? this.user.id : this.user.team_id;
    }
    public isLoading() {
        return this.loading || this.profile.isLoading() || this.users.isLoading();
    }
    toggleDuplicateShareholderMode() {
      this.duplicateShareholderMode = !this.duplicateShareholderMode;
      this.page$.next(1);
    }
    public changeMode(mode: any) {
        this.mode = mode.value;
        this.page$.next(1);
    }
    public changeStatusMode(mode: any) {
        this.statusMode = mode.value;
        this.page$.next(1);
    }
    public changePage(page: number) {
        this.page = page;
        this.page$.next(this.page);
    }
    public refresh() {
        this.changePage(this.page);
    }
    public getModeTitle() {
        switch (this.type) {
            case 'user':
                return 'Assigned to';
            case 'team':
                return 'Assigned to';
            case 'user_owns':
                return 'Owner';
            default:
                return '';
        }
    }
    public getHeaderText() {
        switch (this.type) {
            case 'user':
                return 'Assignments';
            case 'team':
                return 'Team Assignments';
            case 'user_owns':
                return 'Responsibilities';
            default:
                return 'Tasks';
        }
    }
    public getAssignedToName(task) {
        return task.assigned_to_type === 'User' ? task.assigned_to.first_name + ' ' + task.assigned_to.last_name : task.assigned_to.name;
    }
    public toggleComments(task) {
        this.expansionDict['comments-' + task.id] = !this.expansionDict['comments-' + task.id];
    }
    public commentsIsExpanded(task) {
        return this.expansionDict['comments-' + task.id];
    }
    public toggleAssignedTo(task) {
        this.expansionDict['assigned_to-' + task.id] = !this.expansionDict['assigned_to-' + task.id];
    }
    public assignedToIsExpanded(task) {
        return this.expansionDict['assigned_to-' + task.id];
    }
    public toggleDeadline(task) {
        this.expansionDict['deadline-' + task.id] = !this.expansionDict['deadline-' + task.id];
    }
    public deadlineExpanded(task) {
        return this.expansionDict['deadline-' + task.id];
    }

    ngOnDestroy() {
        this.tasksSubscription.unsubscribe();
    }
}
