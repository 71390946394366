import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyReportsService} from '../../../services/company-reports.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'con-company-report-side-menu',
  templateUrl: './company-report-side-menu.component.html',
  styleUrls: ['./company-report-side-menu.component.scss']
})
export class CompanyReportSideMenuComponent implements OnInit, OnDestroy {
  search = true;
  searchInput: string;
  reportButtons: any;

  public loading = true;
  public reports: any;
  private reportsSubscription: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportService: CompanyReportsService,
    private toaster: ToastrService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.reportsSubscription = this.reportService.getReports().subscribe((reports) => {
      reports = this.sortReports(reports);
      this.reports = reports;
      this.reportButtons = reports;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toaster.error('Sorry, some error occurred!');
    });
  }

  sortReports(reports: any) {
    return reports.sort((a, b) => {
      return <any>new Date(b.report_date) - <any>new Date(a.report_date);
    });
  }
  goToReport(report: any) {
    this.router.navigate([report.company_id, 'report', report.id, 'report-overview'], { relativeTo: this.route.parent });
  }
  getReportLink(report: any) {
    return `company_reports/${report.company_id}/report/${report.id}/report-overview`;
  }
  focusInput() {
    this.search = !this.search;
  }
  searchReport(event) {
    if (!this.search) {
      this.searchInput = '';
    }
    this.reportButtons = this.reports.filter(report =>
      (report.report_type + ',' + this.datePipe.transform(report.report_date, 'yyyy-MM-dd')).toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
  }
  ngOnDestroy() {
    if (this.reportsSubscription) {
      this.reportsSubscription.unsubscribe();
    }
  }

}
