<con-loader *ngIf="loading"></con-loader>
<div class="container-fluid mb-3" *ngIf="!profile.isLoading() && !loading">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <div class="page-title-icon">
                    <con-icon-by-name [iconName]="entityDescription?.getIcon()"></con-icon-by-name>
                </div>
                <h3 class="mb-0">{{ entityDescription?.name | prettyCase }}</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4 ml-auto entity-add-form-custom"
            *ngIf="profile.can(
                profile.getPermissionName(entityName,'store')
            )">
            <con-report-file-editor *ngIf="entityName === 'CompanyReportFile'" context="ADD_REPORT_FILE" (afterSave)="redirectToReportFile($event)"></con-report-file-editor>
            <con-entity-form (afterSave)="redirectToEntity($event)"
                            [entityName]="entityName"
                             *ngIf="entityName !== 'CompanyReportFile'"
            ></con-entity-form>
        </div>
    </div>
    <div class="row" *ngIf="profile.can(profile.getPermissionName(entityName,'index'))">
        <div class="col-12">
            <div class="card">
                <con-entity-list [entityName]="entityName"></con-entity-list>
            </div>
        </div>
    </div>
</div>
