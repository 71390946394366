import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { AuthModule } from './../auth/auth.module';
import { EntitiesModule } from './../entities/entities.module';

import { MissingEventsComponent } from './layout/missing-events.component';
import { MissingEventsInstitutionComponent } from './layout/missing-events-institution.component';
import { MissingEventsCompanyComponent } from './layout/missing-events-company.component';
import { MissingEventsStartComponent } from './layout/missing-events-start.component';
import { MissingEventsSearchComponent } from './layout/missing-events-search.component';

import { MissingEventsEditorComponent } from './components/missing-events-editor.component';

import { CountService } from './services/count.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        NgbModule,
        AuthModule,
        EntitiesModule
    ],
    providers: [
        CountService
    ],
    declarations: [
        MissingEventsComponent,
        MissingEventsInstitutionComponent,
        MissingEventsCompanyComponent,
        MissingEventsStartComponent,
        MissingEventsSearchComponent,
        MissingEventsEditorComponent,
    ],
    exports: []
})
export class MissingEventsModule {}
