<div class="full-screen">
    <div class="header">
        <div class="header-left d-flex align-items-center justify-content-center">
            <div class="logo">
                <con-icon-by-name iconName="plug"></con-icon-by-name> connector
            </div>
        </div>
        <div class="header-right">
            <ul class="nav">
                <con-toolbar></con-toolbar>
                <li class="nav-item d-flex align-self-stretch ml-auto profile">
                    <con-profile-handler class="d-flex"></con-profile-handler>
                </li>
            </ul>
        </div>
    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
<div>
