<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <div class="row">
                        <div class="col-6">
                            <div class="page-title-icon">
                                <con-icon-by-name iconName="hand-rock-o"></con-icon-by-name>
                            </div>
                            <h3 class="mb-0">Powers</h3>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link"
                                    [routerLink]="['/powers']"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Jobs</a>
                                </li>
                                <li class="nav-item">
                                        <a class="nav-link"
                                        [routerLink]="['customers']"
                                        routerLinkActive="active">Customer notifications</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link"
                                     [routerLink]="['delta-deliveries']"
                                     routerLinkActive="active">Delta deliveries</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div>
        <div class="alert alert-danger mt-2 mb-10">
          <con-icon-by-name iconName="exclamation-triangle" class="mr-1"></con-icon-by-name>
          <span>
            Disclaimer : Please only use/click bellow buttons in this module if you fully understand implication of change it brings. Triggering actions related to some of the buttons is capable of modifying the data in system and hence may result in inconsistency. Please contact Admin before triggering them, if you are not completely aware of its use case.
          </span>
        </div>
      </div>
    </div>

    <router-outlet></router-outlet>
</div>
