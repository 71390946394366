import { Component, OnInit } from '@angular/core';
import { StaticSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import {filter, take} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';

@Component({
    selector: 'con-static',
    templateUrl: './static.component.html',
    styles: [`.custom-small-loader { margin: 0; display: inline-flex;}`, `.custom-small-loader-width {width: 15px;}`]
})

export class StaticComponent implements OnInit {
    public counts: any = {};

    constructor(public countService: CountService, private router: Router) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routerEvent: RouterEvent) => {
        if (routerEvent.url === '/static') {
          this.resetCount();
        }
      })
    }

    ngOnInit() {
        this.countService.context = '';
        this.countService.getCounts(true).subscribe(counts => {
            this.counts = counts;
        });
    }

    resetCount() {
      this.countService.context = '';
      this.countService.getCounts(true).pipe(take(1)).subscribe(counts => {
        this.counts = counts;
      });
    }
}
