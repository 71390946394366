import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';

import { ValidatorConversionService } from '../../services/validator-conversion.service';
import { EntitiesService, EntityDescription } from '../../services/entities.service';
import { RelationService, Relation } from '../../services/relation.service';

import { ProfileService } from '../../../auth/services/profile.service';

import { snakeCase } from 'change-case';

@Component({
    selector: 'con-belongs-to-form',
    templateUrl: 'belongs-to-form.component.html'
})

export class BelongsToFormComponent implements OnChanges {
    @Input() entity: any;
    @Input() relation: any;
    @Input() form: UntypedFormGroup;
    @Input() ownerType: string;
    @Input() fromShareholder = false;
    @Input() parentCompany: any;
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

    private idControl: AbstractControl;
    private typeControl: AbstractControl;
    private hasRelation: boolean;
    private entityDescriptions: any;
    public relationObject: any;
    public inverseRelations: any;
    public fixedSearchParams: any = {};
    public relations: Relation;

    constructor(private validatorConverter: ValidatorConversionService,
        private relationService: RelationService) { }

    updateCurrentStatus() {
        if (this.entity && this.entity[snakeCase(this.relation.name)]) {
            this.hasRelation = true;
            this.relationObject = this.entity[snakeCase(this.relation.name)];
        } else {
            this.hasRelation = false;
            this.relationObject = {};
        }
    }

    createFormControls() {
        const validators = [];
        if (this.relation.rules) {
            this.relation.rules.forEach(rule => {
                const validator = this.validatorConverter.getValidatorByString(rule);
                if (validator) {
                    validators.push(validator);
                }
            });
        }
        let defaultId = '';
        if (this.entity && this.entity[this.relation.foreign_key]) {
            defaultId = this.entity[this.relation.foreign_key];
        }
        this.idControl = new UntypedFormControl(defaultId, validators);
        this.form.addControl(this.relation.foreign_key, this.idControl)
        this.idControl.valueChanges.subscribe(val => {
            const formValue = this.form.controls[this.relation.foreign_key]
            if (formValue.value !== val) {
                this.form.controls[this.relation.foreign_key].setValue(val);
                // set form objetcs controls value to get updated in parent component
            }

        });

        if (this.isMorphRelation(this.relation)) {

            let defaultType = '';
            if (this.entity && this.entity[this.relation.foreign_type]) {
                defaultType = this.entity[this.relation.foreign_type];
            }
            this.typeControl = new UntypedFormControl(defaultType, []);
            this.form.addControl(this.relation.foreign_type, this.typeControl);

            this.typeControl.valueChanges.subscribe(val => {
                const formValue = this.form.controls[this.relation.foreign_type]
                if (formValue.value !== val) {
                    this.form.controls[this.relation.foreign_type].setValue(val);
                    // set form objetcs controls value to get updated in parent component
                }

            });
        }
    }

    ngOnChanges() {
        this.updateCurrentStatus();

        this.createFormControls();

        this.relations = this.relationService.getRelation(this.relation);

        this.relations.init()
            .subscribe((entityDescription) => {
                this.entityDescriptions = this.relations.getAllRelatedEntityDescriptions();
                this.inverseRelations = this.relations.getAllInverseRelations();
                this.fixedSearchParams = this.relations.getAllFixedSearchParams();
            });
    }

    entityAlreadyExists() {
        return this.entity && this.entity.id !== undefined;
    }

    entityHasRelation() {
        return this.hasRelation;
    }

    getRelatedObjectType() {
        return this.typeControl ? this.typeControl.value : this.relation.model;
    }

    getIconForRelation() {
      if(this.entityDescriptions){
        return this.entityDescriptions[this.getRelatedObjectType()]
            .getIcon();
      }
      return '';
    }

    getPrimaryStringForRelation() {
        return this.entityDescriptions[this.getRelatedObjectType()]
            .getPrimaryString(this.relationObject);
    }

    isMorphRelation(relation) {
        return Relation.isMorphRelationType(relation.type);
    }

    changeRelation(relation, model) {
        this.relationObject = model;
        this.hasRelation = true;
        this.idControl.setValue(model.id);
        this.idControl.markAsDirty();
        this.idControl.markAsTouched();

        if (this.isMorphRelation(relation)) {
            this.typeControl.setValue(relation.owner);
            this.typeControl.markAsDirty();
            this.typeControl.markAsTouched();
        }
        this.onSelect.emit({ model, type: relation.owner });
    }

    empty() {
        this.relationObject = {};
        this.hasRelation = false;
        this.idControl.setValue(null);
        this.idControl.markAsDirty();
        this.idControl.markAsTouched();

        if (this.isMorphRelation(this.relation)) {
            this.typeControl.setValue(null);
            this.typeControl.markAsDirty();
            this.typeControl.markAsTouched();
        }
    }
}
