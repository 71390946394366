
import { of as observableOf, Observable, ReplaySubject, throwError as observableThrowError, from, Subject } from 'rxjs';

import { map, switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { cloneDeep } from 'lodash';


import { EntityHasDependenciesModalComponent } from '../components/views/entity-has-dependencies-modal.component';
import { EntityOutdatedModalComponent } from '../components/views/entity-outdated-modal.component';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { snakeCase } from 'change-case';

import {
  ApiSettings, ShareholderSettings
} from '../../settings.class';
import {MLSendStatus} from '../../company-reports/models/MLSendStatus';
import {environment} from '../../../environments/environment';

export class EntityDescription {
    public name: string;
    timestamps: any[];


    constructor(public data: any) {
        // console.log("data", data);
        this.name = this.data.name;
        this.timestamps = [
            {
                label: 'Created at',
                type: 'date',
                key: 'created_at',
                is_filterable: true
            }, {
                label: 'Updated at',
                type: 'date',
                key: 'updated_at',
                is_filterable: true
            }
        ]
    }
    private appendTimestamps(arr) {
        this.timestamps.forEach(field => {
            arr.push(field);
        });
        return arr;
    }
    public getFieldsForTable(timestamps: boolean = true) {
        const fields = this.data.fields.filter(field => field.show_in_table);
        if (timestamps) {
            return this.appendTimestamps(fields);
        }
        return fields;
    }
    public getFieldsForHistory() {
        return this.appendTimestamps(this.data.fields.filter(field => field.key.indexOf('.') < 0));
    }
    public getFieldsForStoreForm() {
        return this.data.fields.filter(field => field.show_in_store);
    }
    public getFieldsForUpdateForm() {
        return this.data.fields.filter(field => field.show_in_update);
    }
    public getBelongsToRelationsForForm() {
        return this.getAllRelations().filter(relation => ['belongsTo', 'morphTo'].indexOf(relation.type) > -1
            && relation.is_standard_relation);
    }
    public getAllRelations() {
        return this.data.relations;
    }
    public getRelationsOutsideForm() {
        return this.getAllRelations().filter(
            relation => !(relation.is_standard_relation && ['belongsTo', 'morphTo'].indexOf(relation.type) > -1)
        );
    }
    public getPrimaryFields() {
        if (this.name === 'Shareholder') {
            this.data.fields.forEach(field => {
                if (field.key === 'source_type') {
                    field.is_primary = true;
                }
            });
        }
        return this.data.fields.filter(field => field.is_primary);
    }
    public getFilterableFields() {
        return this.appendTimestamps(this.data.fields.filter(field => field.is_filterable));
    }
    public getPrimaryString(entity: any) {
        let res = '';
        let count = 1;
        const fields = this.getPrimaryFields();
        fields.forEach(field => {
            res += this.getAttributeByString(entity, field.key);
            if (count < fields.length) {
                res += ', ';
            }
            count++;
        });
        return res;
    }
    public getAttributeByString(entity: any, attribute: string) {
        return attribute.split('.').reduce((a, b) => (a !== undefined && a !== null) ? a[b] : null, entity);
    }
    public getIcon() {
        return this.data.icon;
    }
    public getData() {
        return this.data;
    }
}

export class Entity {
    private attributes: any;
    private existing = false;
    public id: number;
    constructor(private name: string) {

    }
}


@Injectable()
export class EntitiesService {
    private entityDescriptionsRequest: Observable<EntityDescription[]>;
    private entityDescriptionsSubject: ReplaySubject<EntityDescription[]> = new ReplaySubject(1);
    public calendarEditingSubject: Subject<String> = new Subject();
    public resetDayInput: Subject<String> = new Subject();
    public companyReportFileEditingSubject: Subject<String> = new Subject();
    public combinedStatementListing: Subject<boolean> = new Subject();
    private loading = true;
    private DateApproximation = Observable;
    private TimeApproximation = Observable;
    public DateApproxLoading = false;
    public TimeApproxLoading = false;
    public currentUrl = '';
    public externalEntityDescriptionsSubject: ReplaySubject<EntityDescription[]> = new ReplaySubject(1);
    public forceDelSub: Subject<any> = new Subject();
    private entityFormContent: any;
    constructor (private http: HttpClient,
                 private modalService: NgbModal) {}

    public isLoading() {
        return this.loading;
    }

    public setCalendarEditingSubject(entityName: string) {
        this.calendarEditingSubject.next(entityName);
    }

    public setCompanyReportFileEditingSubject(entityName: string) {
        this.companyReportFileEditingSubject.next(entityName);
    }
    public updateCombinedStatementListingSubject(updateListing : boolean) {
      this.combinedStatementListing.next(updateListing);
    }
    public getInverseRelationType(relationName: string) {
        switch (relationName) {
            case 'hasMany':
                return ['belongsTo'];
            case 'belongsTo':
                return ['hasMany', 'hasOne'];
            case 'hasOne':
                return ['belongsTo'];
            case 'belongsToMany':
                return ['belongsToMany'];
            case 'morphTo':
                return ['morphMany', 'morphOne'];
            case 'morphMany':
                return ['morphTo'];
            case 'morphOne':
                return ['morphTo'];
        }
    }
    public compareRelationKeys(relation1: any, relation2: any) {
        const hasForeignAndSame = (
            ['belongsTo', 'hasMany', 'hasOne'].indexOf(relation1.type) > -1
            && relation1.foreign_key === relation2.foreign_key
        );

        const hasRelatedAndSame = (
            ['belongsToMany'].indexOf(relation1.type) > -1
            && ['belongsToMany'].indexOf(relation2.type) > -1
            && relation1.related_key === relation2.foreign_key
            && relation2.related_key === relation1.foreign_key
            && relation1.table === relation2.table
        );

        const isMorph = (
            ['morphTo', 'morphOne', 'morphMany'].indexOf(relation1.type) > -1
            || ['morphTo', 'morphOne', 'morphMany'].indexOf(relation2.type) > -1
        )
        return hasForeignAndSame || hasRelatedAndSame || isMorph;
    }
    public compareRelationModelNames(entityName: string, relation: any) {
        const models = (relation.model ? [relation.model] : relation.models).map(modelName => modelName.toLowerCase());
        return models.indexOf(entityName.toLowerCase()) > -1
    }
    public isInverseRelation(relation: any) {
        const inverseTypes = this.getInverseRelationType(relation.type);
        return (r) => {
            return inverseTypes.indexOf(r.type) > -1 &&
                this.compareRelationModelNames(relation.owner, r) &&
                this.compareRelationKeys(r, relation);
        }
    }
    public getInverseRelation(relation: any) {
        return this.getEntityDescriptionByEntityName(relation.model).pipe(
            map(entityDescription => entityDescription.getAllRelations()),
            map(relations => relations.filter(this.isInverseRelation(relation))[0]));
    }
    public getInverseRelations(relation: any) {
        return this.getEntityDescriptionsByEntityNames(relation.model ? [relation.model] : relation.models).pipe(
            map(entityDescriptions => {
                return entityDescriptions.map(entityDescription => entityDescription.getAllRelations());
            }),
            map(arraysOfRelations => {
                let relations = [];
                arraysOfRelations.forEach(rs => relations = relations.concat(rs));
                return relations;
            }),
            map(relations => relations.filter(this.isInverseRelation(relation))));
    }
    public getEntityDescriptionsByEntityNames(entityNames: any) {
        return this.entityDescriptionsSubject.pipe(
            map(entityDescriptions => {
                entityDescriptions = entityDescriptions.filter(entityDescription =>
                    entityNames.indexOf(entityDescription.name) > -1);
                if (entityDescriptions.length !== entityNames.length) { throw observableThrowError('Not found') };
                return entityDescriptions;
            }));
    }
    public getAllEntityDescriptions(refresh: boolean = false): Observable<EntityDescription[]> {
        if ((refresh || !this.entityDescriptionsRequest)) {
            this.loading = true;
            this.entityDescriptionsRequest = this.http.get<any>(
                this.urlFromArray([ApiSettings.BASE_URL,
                ApiSettings.META_ENDPOINT])
            ).pipe(
                map(res => res.map(entityDescription => new EntityDescription(entityDescription))));

            this.entityDescriptionsRequest.subscribe(
                result => {
                    const item = result.find(item => item.name === 'CompanyReportFile')
                    item.data.fields[2].show_in_table = true;
                    this.entityDescriptionsSubject.next(result);
                    this.loading = false;
                },
                err => {
                    this.entityDescriptionsSubject.error(err);
                    this.loading = false;
                });
        }

        return this.entityDescriptionsSubject.asObservable();
    }

    public getEntityDescriptionByEntityName(name: string): Observable<EntityDescription> {
        return this.getAllEntityDescriptions().pipe(
                        map(entityDescriptions => {
                                const entityDescription = entityDescriptions.filter(entityDesc => entityDesc.name === name);
                                if (!entityDescription.length) { throw observableThrowError('Not found') };

                                return entityDescription[0];
                            }));
    }
    public getAllExternalEntityDescriptions(): Observable<EntityDescription[]> {
        return this.externalEntityDescriptionsSubject.asObservable();
    }
    public getExternalEntityDescriptionByEntityName(name: string): Observable<EntityDescription> {
        return this.getAllExternalEntityDescriptions().pipe(
            map(entityDescriptions => {
                const entityDescription = entityDescriptions.filter(Description => Description.name === name);
                if (!entityDescription.length) { throw Observable.throw('Not found') };
                const entityDescriptionExternal = new EntityDescription(entityDescription[0]);
                    return entityDescriptionExternal;
            })
        )

    }
    public getEntities(name: string, page: number = 1, per_page?: number): Observable<any> {
        let params = new HttpParams();
        params = params.set('page', page.toString());
        if (per_page) {
            params = params.set('per_page', per_page.toString())
        }
        return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL, snakeCase(name)]),
            {
                params
            });
    }
    public setSearchParams(params: HttpParams, terms: any) {
        for (const key in terms) {
            if (terms.hasOwnProperty(key)) {
                let searches = terms[key];
                if (!Array.isArray(searches)) {
                    searches = [searches];
                }

                if (key !== '') {
                    const values = [];
                    const field = 'q:' + key.replace('.', ':')
                        .split(':')
                        .map(k => snakeCase(k))
                        .join(':')
                    searches.forEach(term => {
                        if (term !== '') {
                            let value;
                            switch (typeof term) {
                                case 'boolean':
                                    value = term ? 'f:true' : 'f:false';
                                    break;
                                case 'object':
                                    if (term === null) {
                                        value = 'f:null';
                                    }
                                    break;
                                default:
                                    value = term;
                            }
                            values.push(value);
                        }
                    });
                    if (values.length) {
                        params = params.set(field, values.join('&'));
                    }
                }
            }

        }
        if (terms['']) {
            params = params.set('q', terms['']);
        }

        return params;
    }
    private addPaginationParams(params: HttpParams, page: number = 1, order_by?: string, order_asc?: boolean, per_page?: number) {
        params = params.set('page', page.toString());
        if (per_page) {
            params = params.set('per_page', per_page.toString())
        }
        if (order_by) {
            params = params.set('order_by', order_by);
        }
        if (order_asc) {
            params = params.set('asc', 'true');
        }
        return params
    }
    public set dateApproximation(approx: any) {
        this.DateApproximation = approx;
    }
    public get dateApproximation() {
        return this.DateApproximation;
    }
    public set timeApproximation(approx: any) {
        this.TimeApproximation = approx;
    }
    public get timeApproximation() {
        return this.TimeApproximation;
    }
    clearDateTimeApproximation(url: string) {
        if (this.currentUrl !== url) {
            this.timeApproximation = {};
            this.dateApproximation = {};
        }
        this.currentUrl = url;

    }
    filterApproximation(approx: any, key: string) {
        const approxData = approx;
        const data = approx.data.filter(result => {
            return result.name.toLowerCase().indexOf(key.toLowerCase().trim()) === 0;
        });
        approxData.data = data;
        return approxData;
    }
    public searchEntities(name: string, terms: any, extras:any): Observable<any> {
        const { page = 1, order_by, order_asc, per_page, relation } = extras;
        if (name === 'DateApproximation' && this.dateApproximation.data && this.dateApproximation.data.length > 0) {
            const approx = cloneDeep(this.dateApproximation);
            return observableOf(this.filterApproximation(approx, terms['']));
        } else if (name === 'TimeApproximation' && this.timeApproximation.data && this.timeApproximation.data.length > 0) {
            const approx = cloneDeep(this.timeApproximation);
            return observableOf(this.filterApproximation(approx, terms['']));
        }
        let params = new HttpParams();
        params = this.setSearchParams(params, terms);
        params = this.addPaginationParams(params, page, order_by, order_asc, per_page);
        if(relation && relation.name === 'companyReportEvents') {
          return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL, 'company_tier',environment.company_report_tier,'companies']),
            {
              params
            });
        }
        return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL, snakeCase(name)]),
            {
                params
            });
    }
    public getLocalStorageObject(name: string, label: string) {
        const selectedObject = JSON.parse(localStorage.getItem(label));
        if (!selectedObject || !selectedObject[name]) {
            return [];
        } else {
            return selectedObject[name];
        }
    }
    public createExternalEntities(name: string,  terms: any, page: number = 1, order_by?: string,
        order_asc?: boolean, per_page?: number) {
        let params = new HttpParams();
        params = this.setSearchParams(params, terms);
        params = this.addPaginationParams(params, page, order_by, order_asc, per_page);
        return params;
    }
    public searchRelation(name: string, id: number, relation: string, terms: any, page: number = 1,
        order_by?: string, order_asc?: boolean, per_page?: number): Observable<any> {
        let params = new HttpParams();
        params = this.setSearchParams(params, terms);
        params = this.addPaginationParams(params, page, order_by, order_asc, per_page);
        return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL,
            snakeCase(name),
            id.toString(),
            snakeCase(relation)]),
            {
                params
            });
    }


    public getEntityById(name: string, id: number): Observable<any> {
        return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL,
            snakeCase(name),
                id])

        );

    }
    public urlFromArray(arr: any) {
        let url = '';
        let count = 0;
        arr.forEach(part => {
            if (count > 0) {
                url += '/'
            }
            url += part;
            count += 1
        });
        return url;
    }
    public getEntityHistory(name: string, id: number, page: number = 1): Observable<any> {
        let params = new HttpParams();
        params = params.set('page', page.toString());
        return this.http.get(this.urlFromArray([
            ApiSettings.BASE_URL,
            snakeCase(name),
            id,
            ApiSettings.HISTORY_ENDPOINT
        ]),
            {
                params
            });
    }
    public saveEntity(name: string, data: any): Observable<any> {
        let request;
        if (data.id !== undefined) {
            request = this.http.put(
                this.urlFromArray([ApiSettings.BASE_URL,
                snakeCase(name),
                data.id]),
                data);
        } else {
            if(name === 'CombinedStatements' && data.hasOwnProperty('combined_edit_mode')) {
              request = this.http.put(
                this.urlFromArray([ApiSettings.BASE_URL, 'company_report', 'statements', data.company_report_id]),
                data);
            } else if(name === 'CombinedStatements'){
              request = this.http.post(
                this.urlFromArray([ApiSettings.BASE_URL, 'company_report', 'statements']),
                data);
            } else {
              request = this.http.post(
                  this.urlFromArray([ApiSettings.BASE_URL,
                  snakeCase(name)]),
                  data);
            }
        }
        return request.pipe(catchError(err => {
            if (err.isEntityOutdatedError()) {
                const openModal = this.modalService.open(EntityOutdatedModalComponent, { size: 'lg' });

                const changedEntity = err.getData().entity;
                const o = {};
                for (const key in changedEntity) {
                    if (changedEntity.hasOwnProperty(key)) {
                        o[key] = changedEntity[key];
                    }
                }
                for (const key in data) {
                    if (key !== 'updated_at') {
                        o[key] = data[key];
                    }
                }

                openModal.componentInstance.setObjects(changedEntity, o);

                return from(openModal.result).pipe(switchMap(status => {
                    delete data['updated_at'];

                    return this.saveEntity(name, data);
                }),
                    catchError(res => {
                        return observableOf(changedEntity);
                    }));
            }


            return observableThrowError(err);
        }));
    }
    public addRelation(name: string, id: number, relationName: string, ids: any, pivot: any = {}) {
        return this.http.put(
            this.urlFromArray([ApiSettings.BASE_URL,
            snakeCase(name),
                id,
            snakeCase(relationName),
            ApiSettings.RELATION_ADD]),
            { ids, pivot });

    }
    public includeAll(name: string, tierId: any) {
        return this.http.post(
            this.urlFromArray([ApiSettings.BASE_URL,
            snakeCase(name),
            tierId,
            ApiSettings.INCLUDE_ALL]),
            { });

    }
    public updateRelation(name: string, id: number, relationName: string, relatedId: number, pivot: any = {}) {
        return this.http.put(
            this.urlFromArray([
                ApiSettings.BASE_URL,
                snakeCase(name),
                id,
                snakeCase(relationName),
                relatedId
            ]),
            { pivot }
        );
    }
    public getRelation(name: string, id: number, relationName: string): Observable<any> {
        return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL,
            snakeCase(name),
                id,
            snakeCase(relationName)])
        );
    }
    public removeRelation(name: string, id: number, relationName: string, ids: any) {
        return this.http.put(
            this.urlFromArray([ApiSettings.BASE_URL,
            snakeCase(name),
                id,
            snakeCase(relationName),
            ApiSettings.RELATION_REMOVE]),
            { ids }
        );

    }

    public deleteEntity(name: string, entity: any): Observable<any> {
        return this.http
                    .delete(
                        this.urlFromArray(
                            [
                                ApiSettings.BASE_URL,
                                snakeCase(name),
                                entity.id
                            ]
                        )
                    ).pipe(
                    catchError(err => {
                        if (err.isHasDependentEntitiesError()) {
                            const openModal = this.modalService.open(EntityHasDependenciesModalComponent);
                            openModal.componentInstance.setEntities(err.getData().entities);
                            openModal.result.then((result) => {
                              this.forceDelSub.next({name: name, entity: entity, reload: false});
                            });
                        }
                    return observableThrowError(err);
                }));
    }

    public checkOwnershipExists(name: string, id: number, relationName: string, date: string): any {
        let params = new HttpParams();
        params = params.set('page', '1');
        if (relationName === 'Shareholders') {
            params = params.set('q:shareholder_date', date);
        } else {
            params = params.set('q:date', date);
        }
        return this.http.get(
            this.urlFromArray([ApiSettings.BASE_URL, snakeCase(name),
                id,
            snakeCase(relationName)]),
            {
                params
            });
    }

    public isEntityLocked(entityObject: any, entityName?: string) {
        if (entityObject.locked && entityName !== 'CompanyReport' && entityName !== 'Taxonomy') {
            return true
        }
        return false
    }
    public entityLockedByMl(entityObject: any) : boolean{

      if(entityObject?.ml_info){

        const entityTypeMLInfo = entityObject.ml_info;

        return (entityTypeMLInfo?.ml_request_status !== null &&
          entityTypeMLInfo?.ml_request_status !== MLSendStatus.FAILED &&
          entityTypeMLInfo?.ml_request_status !== MLSendStatus.CLOSED);

      } else if(entityObject?.company_report) {

        const companyReportTypeMLInfo = entityObject.company_report?.ml_info;

        return (companyReportTypeMLInfo?.ml_request_status !== null &&
          companyReportTypeMLInfo?.ml_request_status !== MLSendStatus.FAILED &&
          companyReportTypeMLInfo?.ml_request_status !== MLSendStatus.CLOSED);

      } else if(entityObject?.source) {

        const sourceTypeMLInfo = entityObject.source?.ml_info;

        if(sourceTypeMLInfo) {
          return (
            sourceTypeMLInfo?.ml_request_status !== null &&
            sourceTypeMLInfo?.ml_request_status !== MLSendStatus.FAILED &&
            sourceTypeMLInfo?.ml_request_status !== MLSendStatus.CLOSED);

        }
      }
      return false;
    }
    public isEntityCalculated(entityObject: any, entityName?: string) {
        if (entityName === 'KpiFigure' && entityObject.kpi_module && entityObject.kpi_module.calculated === true) {
            return true
        }
        return false
    }
    public getPrefillData(entityName, fixedParam, parent) {
        return this.http.get(this.urlFromArray(
            [
                ApiSettings.BASE_URL,
                'company',
                parent.id,
                'prefill'
            ]
        ), {
          params: {
            report_type: parent.report_type
          }
        })
    }

    public forceDelete(name: string, id: number): Observable<any> {
        return this.http
                    .delete(
                        this.urlFromArray(
                            [
                                ApiSettings.BASE_URL,
                                snakeCase(name),
                                id,
                                'force_delete'
                            ]
                        )
                    ).pipe(
                    catchError(err => {
                        return observableThrowError(err);
                    }));
    }

    saveAllOwnersInShareholder (shareholderId: number,payload) {
      return this.http.post(ShareholderSettings.BASE_URL+ `/store_owners/${shareholderId}/`, payload)
    }

    setEntityForEntityFormContent(entity) {
      this.entityFormContent = entity
    }
    getEntityForEntityFormContent() {
      return this.entityFormContent;
    }

}
