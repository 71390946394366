import { Component, Input, OnChanges } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { ApiSettings, CalendarSettings } from '../../settings.class';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';


import * as moment from 'moment';

@Component({
    selector: 'con-missing-events',
    templateUrl: './missing-events.component.html'
})

export class MissingEventsComponent implements OnChanges {
    @Input() company: any;
    @Input() ownerType: string;

    public loading: boolean;
    public error: boolean;
    public missingTypes: any;
    public now: string = this.dateConverter.toEntityString(moment());
    public showNewEventFlag = false;
    public expansionDict: any = {};
    public expanded = true;
    constructor(private http: HttpClient,
                public profile: ProfileService,
                private dateConverter: DateConverterService) {}

    showAll() {
        this.missingTypes.forEach(event => {
            event.expanded = true;
        });
        this.company.expanded = true;
    }
    public toggleType(event?) {
        if (event) {
            event.expanded = !event.expanded;
        } else {
           this.expanded = ! this.expanded;
        }
    }
    public toggleEdit(event?) {
        if (!event) {
            this.company.expanded = ! this.company.expanded;
        } else {
            event.expanded = ! event.expanded;
        }
    }
    showEvent(event?) {
        if (!event && (this.company.expanded)) {
            return true;
        } else if (event && event.expanded) {
            return true;
        } else {
            return false;
        }
    }
    clearEditor() {
        if (this.company.currentEditingEvent) {
            this.company.currentEditingEvent = null;
        } else {
            this.showNewEventFlag = false;
        }
    }
    setCommentFlag(flag, event?) {
        if (!event) {
            this.company.expanded = flag;
        } else {
            event.expanded = flag;
        }
    }
    getOwnerTypeUri(): string {
        const url = this.ownerType === 'Institution' ? '/institution/' : '/company/';
        return url;
    }

    getMutations(event?) {
        if (event) {
            const mutation = event.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
            return mutation;
        } else {
            const mutation = this.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
            return mutation;
        }
    }

    show() {
        if (!this.loading && !this.error && this.expanded) {
            return true;
        } else {
            return false;
        }
    }
    newEventChanged(event: any) {
        this.company.currentEditingEvent = event;
        this.company.currentEditingEvent.editingCommentsDict = {};
    }
    public eventCreated(event: any) {
        event.editingCommentsDict = {};
        const id = event.calendar_event_type.id;
        const indx = this.missingTypes.findIndex(t => t.id === id);
        this.missingTypes[indx].missing_event = event;
        let allEventsCreated = true;
        this.missingTypes.forEach(missing => {
            if (!missing.missing_event || !missing.missing_event.id) {
                allEventsCreated = false;
            }
        });
        if (allEventsCreated) {
            this.http.put(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.MISSING_FIXED_ENDPOINT, {})
                     .subscribe();
        }
    }
    toggleEditOfComment(comment, currentEditingEvent) {
        if (!(comment.id in currentEditingEvent.editingCommentsDict)) {
            currentEditingEvent.editingCommentsDict[comment.id] = false;
        }
        currentEditingEvent.editingCommentsDict[comment.id] = !currentEditingEvent.editingCommentsDict[comment.id];
    }
    isEditingComment(comment, currentEditingEvent?) {
        if (currentEditingEvent) {
            return currentEditingEvent.editingCommentsDict[comment.id];
        } else {
            return false;
        }
    }
    commentAdded(comment, currentEditingEvent) {
        currentEditingEvent.comments.push(comment);
    }
    commentUpdated(comment, currentEditingEvent) {
        const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
          currentEditingEvent.comments[index] = comment;
        }
        this.toggleEditOfComment(comment, currentEditingEvent);
    }
    commentDeleted(comment, currentEditingEvent) {
        const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
            currentEditingEvent.comments.splice(1, index);
        }
        this.toggleEditOfComment(comment, currentEditingEvent);
    }
    ngOnChanges() {
        if (this.company && this.company.id) {
            this.loading = true;
            this.http.get(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.MISSING_ENDPOINT)
                    .subscribe(res => {
                         this.missingTypes = res;
                         this.showAll();
                         this.loading = false;
                     }, err => {
                         console.log(err);
                         this.error = true;
                         this.loading = false;
                     });
        }
    }
}
