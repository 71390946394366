
import {of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {catchError, map, debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import { Component, Input, Output, OnInit, OnChanges, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { ProfileService } from '../../../auth/services/profile.service';
import * as moment from 'moment';








import { EntitiesService, EntityDescription } from '../../services/entities.service';
import { Router } from '@angular/router';


@Component({
    selector: 'con-entity-search-field',
    templateUrl: './entity-search-field.component.html'
})

export class EntitySearchFieldComponent implements OnInit, OnChanges, OnDestroy {
    @Input() entityName: string;
    @Input() relation: any;
    @Input() fromShareholder = false;
    @Input() fixedSearchParams: any = {};
    @Input() disabled: boolean;
    @Input() ownerType: string;
    @Input() parentCompany: any;
    @Input() parentEntity: any;
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
    @Input() showDismiss: Boolean = false;
    @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('searchInput') searchInput: ElementRef;

    public model: any;
    public searching = false;
    public searchFailed = false;
    private totalResult = 0;
    private showing = 0;
    public entityDescription: EntityDescription;
    public entityDescriptionSubscription: Subscription = new Subscription();

    public _canIndex: boolean;
    private profileReadySubscription: Subscription;

    public isLoading() {
        return this.entitiesService.isLoading();
    }

    public dismiss() {
        this.onDismiss.next();
    }

    public search = (text$: Observable<string>) => {
        return text$.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => this.searching = true),
          switchMap(term => {
              const terms = this.fixedSearchParams;
              terms[''] = term;
              if (this.entityName === 'TaxonomyLine') {
                terms['taxonomy_id'] = this.parentEntity.taxonomy_id;
              }
              if (this.entityName === 'CompanyReport' && this.fromShareholder) {
                  terms['company_id'] = this.parentCompany.id;
              } else if (this.entityName === 'Company' && this.fromShareholder) {
                const singleCompanyObject = {
                    data : [{
                        id: this.parentCompany.id,
                        name: this.parentCompany.name
                    }],
                    total: 1
                }
                this.searchFailed = false;
                this.searching = false;
                return observableOf(singleCompanyObject.data);
              }
              const obs$ = this.entitiesService.searchEntities(this.entityName, terms, {relation: this.relation});
              return obs$.pipe(map(entities => {
                                if (this.entityName === 'CalendarEventType' && this.ownerType) {
                                    const searchList = entities.data.filter(entity => entity.type === this.ownerType);
                                    this.totalResult = searchList.total;
                                    this.showing = searchList.total
                                    return searchList;
                                }
                                if (this.entityName === 'CompanyReport' && this.fromShareholder) {
                                    entities.data.forEach(report => {
                                        if (report.company) {
                                            report.company.name = report.company.name + ' - ' + moment(report.report_date). format('yyyy-MM-DD');
                                        }
                                    });
                                }
                                if (this.entityName === 'CompanyRole') {
                                    entities.data.forEach(report => {
                                        if (report.name && report.type_name) {
                                            report.name = report.name + ', ' + report.type_name;
                                        }
                                    });
                                }
                                this.totalResult = entities.total;
                                this.showing = entities.data.length;
                                return entities.data;
                            }),
                            tap(() => {
                                this.searchFailed = false;
                                this.searching = false;
                            }),
                            catchError(() => {
                              this.searchFailed = true;
                              return observableOf([]);
                          }), );
          }), );
    }
    private formatter = (entity: any) => {
        return this.entityDescription.getPrimaryString(entity);
    };
    constructor(private entitiesService: EntitiesService,
                private profileService: ProfileService,
                private router: Router) {}

    private generatePermissions() {
        this._canIndex = (this.disabled === undefined) ? this.profileService.can
        (this.profileService.getPermissionName(this.entityName, 'index')) : !this.disabled;
    }

    ngOnInit() {
        this.profileReadySubscription = this.profileService.whenReady()
            .subscribe(res => {
                if (res) {
                    this.generatePermissions();
                }
            });
        this.entitiesService.currentUrl = this.router.url;
        if (this.entityName === 'DateApproximation' &&
                !this.entitiesService.dateApproximation.data &&
                !this.entitiesService.DateApproxLoading) {
            this.entitiesService.DateApproxLoading = true;
            this.entitiesService.searchEntities('DateApproximation', '' , {})
                .subscribe(res => {
                    this.entitiesService.dateApproximation = res;
                    this.entitiesService.DateApproxLoading = false;
                });
        }
        if (this.entityName === 'TimeApproximation' &&
                !this.entitiesService.timeApproximation.data &&
                !this.entitiesService.TimeApproxLoading) {
            this.entitiesService.TimeApproxLoading = true;
            this.entitiesService.searchEntities('TimeApproximation', '', {})
                .subscribe(res => {
                    this.entitiesService.timeApproximation = res;
                    this.entitiesService.TimeApproxLoading = false;
                });
        }
    }

    ngOnDestroy() {
        this.entitiesService.clearDateTimeApproximation(this.router.url);
        this.profileReadySubscription.unsubscribe();
        this.entityDescriptionSubscription.unsubscribe();
    }

    ngOnChanges($event) {
        this.generatePermissions();

        this.entityDescriptionSubscription.unsubscribe();

        this.entityDescriptionSubscription = this.entitiesService.getEntityDescriptionByEntityName(this.entityName)
                                                                 .subscribe(entityDescription =>
                                                                    this.entityDescription = entityDescription);
    }
    entitySelected(model: any) {
        if (model && model.id !== undefined) {
            this.onSelect.emit(model);
            this.model = '';
            this.searchInput.nativeElement.value = '';
        }
    }
}
