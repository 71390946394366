import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../../auth/services/profile.service';
import { CalendarSettings } from '../../../settings.class';

import { DateConverterService } from '../../../shared/services/date-converter.service';

import * as moment from 'moment';

@Component({
    selector: 'con-start',
    templateUrl: './start.component.html'
})

export class StartComponent {
    public tierId: number = CalendarSettings.TIER_ID;
    public institutionTierId: number = CalendarSettings.INSTITUITION_TIER_ID;
    public now: string = this.dateConverter.toEntityString(moment());

    constructor(private router: Router,
                public profile: ProfileService,
                private dateConverter: DateConverterService) {}

    public navigateToCompany(company: any) {
        this.router.navigate(['calendar', 'company', company.id]);
    }

    public navigateToInstitution(institution: any) {
        this.router.navigate(['calendar', 'institution', institution.id]);
    }

    public getNow() {
        return
    }
}
