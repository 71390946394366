import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'con-inside',
    templateUrl: './inside.component.html'
})

export class InsideComponent {
    constructor(private router: Router) {}
}
