import { Component, Input } from '@angular/core';

@Component({
    selector: 'con-object-inspector',
    templateUrl: './object-inspector.component.html'
})

export class ObjectInspectorComponent {
    @Input() object: any;

    getKeys() {
        return this.object ? Object.keys(this.object) : [];
    }

    isObject(obj: any) {
        return obj !== null && typeof obj === 'object';
    }

    isNull(value) {
        return value === null;
    }

    getString() {
        return JSON.stringify(this.object, null, 4);
    }
}
