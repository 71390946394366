<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Company Employees</h3>
            </div>
        </div>
    </div>
    <con-loader *ngIf="loading || settingLock"></con-loader>
    <div class="row" *ngIf="!loading && reportEntity && !settingLock">
        <div class="col-12">
            <div class="d-flex justify-content-start sticky-header non-sticky py-2">
                <h3 class="mb-0"><span class="badge badge-dark">{{getDisplayTitle()}}</span></h3>
              <span *ngIf="entity?.company?.website" class="website-link-positioning">
                <a [href]=entity?.company?.website class="link-fontsize" target="_blank">
                  {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
              </span>
                <div class="chip chip-inverse ml-auto">
                    <con-avatar [userId]="entity?.company?.manager?.id" [size]="32"></con-avatar>
                    {{entity?.company?.manager?.first_name}} {{entity?.company?.manager?.last_name}}
                </div>
            </div>
            <div class="alert ml-status-created" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CREATED">
              <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.CREATED_MESSAGE}}
            </div>
            <div class="alert ml-status-in-progress" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.IN_PROGRESS">
              <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.IN_PROGRESS_MESSAGE}}
            </div>
            <div class="alert ml-status-submitted" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.SUBMITTED">
              <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.SUBMITTED_MESSAGE}}
            </div>
            <div class="alert ml-status-closed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CLOSED">
              <con-icon-by-name iconName="check"></con-icon-by-name> {{MLSendStatus.CLOSED_MESSAGE}}
            </div>
            <div class="alert ml-status-failed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.FAILED">
              <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.FAILED_MESSAGE}}
            </div>
            <con-no-data *ngIf="entity?.locked && !settingLock && !loading" count="0" class="locked-report"></con-no-data>
            <con-to-many-editor *ngIf="reportEntity && relation"
                                canShow="true"
                                [canEdit]="!entity?.locked && !entityService.entityLockedByMl(entity)"
                                [showEdit]="!entity?.locked && !entityService.entityLockedByMl(entity)"
                                [relation]="relation"
                                [entity]="reportEntity"
                                [fromReports]="true"
                                [parentCompany]="entity.company.id"
                                [isEmployeeFigures]="true"
            >

            </con-to-many-editor>
        </div>
    </div>
</div>
