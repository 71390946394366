import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ApiSettings, AuthSettings } from '../../settings.class';

import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'con-file-uploader',
    templateUrl: './file-uploader.component.html'
})

export class FileUploaderComponent implements OnInit, OnChanges {
    @Input() type: string;
    @Input() control: UntypedFormControl;

    public hasSubmitted = false;
    public hadFile: boolean;
    public uploader: FileUploader;

    private UPLOAD_CONFIG: any = {
        url: ApiSettings.BASE_URL + '/' + ApiSettings.TEMPORARY_STORAGE,
        autoUpload: true,
        authTokenHeader: AuthSettings.HEADER_NAME,
        authToken: AuthSettings.HEADER_PREFIX + ' ' + AuthSettings.getToken()
    }

    hasFile() {
        return this.control.value !== '';
    }
    setFile(file_name: string) {
        this.control.patchValue(file_name);
        this.control.markAsDirty();
        this.control.markAsTouched();
    }
    emptyFile() {
        this.setFile('');
        this.uploader.clearQueue();
    }
    ngOnChanges() {
        this.hasSubmitted = false;
        this.hadFile = this.hasFile();
        this.uploader = new FileUploader(this.UPLOAD_CONFIG);
        this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
            this.setFile(JSON.parse(response).file_name);
            this.hasSubmitted = true;
        }
    }
    ngOnInit() {

    }
    isImage() {
        return this.type === 'image';
    }
}
