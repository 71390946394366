import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

export interface TreeNode {
  initialValue_rep: any;
  module_id: number;
  name: string;
  tree_name: string;
  level: boolean;
  position: number;
  line_id: number;
  parent_id?: any;
  figure_id?: any;
  value_rep?: any;
  has_error?: boolean;
  display_value?: any;
  showChildren?: boolean;
  children?: TreeNode[];
  has_limit_error?: boolean;
  focus?: boolean;
  prev_year_value?: any;
  prev_display_value?: any;
  showPrevious?: boolean;
  is_key_financial?: boolean;
}

@Component({
  selector: 'con-income-statement-editor',
  templateUrl: './income-statement-editor.component.html',
  styleUrls: ['./income-statement-editor.component.scss'],
})
export class IncomeStatementEditorComponent implements OnChanges {
  @Input() treeData: Array<TreeNode> = [];
  @Input() parent: TreeNode;
  @Input() quantity: number;
  @Input() locked: boolean;
  @Input() entity: any;
  @Input() showPrevious: boolean;
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMaxValueReached: EventEmitter<any> = new EventEmitter<any>();

  public mask: any = {
    mask: Number,
    thousandsSeparator: ' ',
    scale: 7,
    radix: '.',
    mapToRadix: [','],
    min: -999999999999999,
  };

  ngOnChanges() {
    // max 15 digit and 7 float

    this.treeData.forEach((item) => {
      if (item.value_rep && !isNaN(item.value_rep)) {
        if (this.quantity === 0.01) {
          item.display_value = (item.value_rep / this.quantity).toFixed(4).toString();
        } else {
          item.display_value = (item.value_rep / this.quantity).toString();
        }
      }
      item.initialValue_rep = item.value_rep;
      if (item.prev_year_value && !isNaN(item.prev_year_value)) {
        if (this.quantity === 0.01) {
          item.prev_display_value = (item.prev_year_value / this.quantity).toFixed(4).toString();
        } else {
          item.prev_display_value = (item.prev_year_value / this.quantity).toString();
        }
      }
      // check sum of childrens value is same
      if (item.children && item.children.length > 0) {
        // expand node by defualt
        item.showChildren = true;
        const sumDownWard = this.calculateSumDownward(item, 'value_rep');
        if (
          parseFloat(item.value_rep) !==
          sumDownWard &&
          sumDownWard > 0
        ) {
          item.has_error = true;
        }
      }
    });
  }

  toggleChild(node) {
    node.showChildren = !node.showChildren;
  }
  valueChanged(event, node) {
    if (this.parent) {
      this.parent.display_value = this.calculateSumUpward(
        this.treeData
      ).toString();
      this.parent.value_rep = this.parent.display_value * this.quantity;
      if (this.parent.value_rep) {
        this.parent.value_rep = parseFloat(this.parent.value_rep.toFixed(4));
      }
      this.onValueChanged.emit(this.parent);
    } else {
      // to enable action buttons
      this.onValueChanged.emit(node.line_id);
    }

    node.value_rep = node.display_value
      ? node.display_value * this.quantity
      : null;
    if (node.value_rep) {
      node.value_rep = parseFloat(node.value_rep.toFixed(4));
    }
    this.checkNodeHasMaxValueError(node);
    // if node has limit value error
    if (node.has_limit_error) {
      event.currentTarget.focus();
    }
    // validate max value
    if (node.children && node.children.length > 0) {
      const sum = this.calculateSumDownward(node, 'display_value');
      if (
        sum > 0 &&
        sum !== parseFloat(node.display_value) &&
        parseFloat(node.initialValue_rep) !== node.value_rep
      ) {
        node.has_error = true;
      } else {
        node.has_error = false;
      }
    }
  }

  checkNodeHasMaxValueError(node) {
    let action = 'remove';
    if (node.value_rep) {
      let numberArray = node.value_rep.toString();
      numberArray = numberArray.replace('-', '');
      numberArray = numberArray.split('.');

      if (numberArray.length === 1) {
        if (numberArray[0].length > 15) {
          action = 'add';
        }
      } else if (numberArray.length === 2) {
        if (numberArray[0].length > 15 || numberArray[1].length > 7) {
          action = 'add';
        }
      }
    }

    node.has_limit_error = action === 'add' ? true : false;
    this.onMaxValueReached.emit({
      node: {
        figure_id: node.figure_id,
        module_id: node.module_id,
        name: node.name,
        quantity: this.quantity,
      },
      action: action,
    });
  }
  calculateSumUpward(treeData: TreeNode[]): number {
    let sum: any = 0;
    treeData.forEach((item) => {
      if (item.display_value && item.display_value.trim() && !isNaN(item.display_value)) {
        sum += parseFloat(item.display_value);
      }
    });
    this.parent.has_error = false;
    if (sum) {
      sum = parseFloat(sum.toFixed(4));
    }
    return sum;
  }

  calculateSumDownward(treeData: TreeNode, field: string): number {
    let sum: any = 0;
    if (treeData.children) {
      treeData.children.forEach((item) => {
        if (item[field] && !isNaN(item[field])) {
          sum += parseFloat(item[field]);
        }
      });
    }
    if (sum) {
      sum = parseFloat(sum.toFixed(4));
    }
    return sum;
  }

  detectChange(data) {
    if (this.parent) {
      this.parent.display_value = this.calculateSumUpward(
        this.treeData
      ).toString();
      this.parent.value_rep = this.parent.display_value * this.quantity;
      this.onValueChanged.emit(data);
    } else {
      // to enable action buttons
      this.onValueChanged.emit(data.parent_id ? data.parent_id : data.line_id);
    }
    this.checkNodeHasMaxValueError(data);
  }

  onReachingMaxValue(event) {
    this.onMaxValueReached.emit(event);
  }
  formatTooltip(data) : string {
    if(data) {
      return  (data as string).replace('_', ' ');
    }
    return '';
  }
  enable_row_highlight(is_highlighted){
    return !!(this.entity && this.entity.report_type == 'interim' && is_highlighted);
  }
}
