import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, FormGroup} from '@angular/forms';
import {shareReplay} from 'rxjs/operators';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {CompanyReportsSettings} from '../../../settings.class';

@Component({
  selector: 'con-filter-report',
  templateUrl: './filter-report.component.html',
  styleUrls: ['./filter-report.component.scss']
})
export class FilterReportComponent implements OnInit{

  fromDateControl = new UntypedFormControl('');
  fromDateField = {
    key: 'from_date'
  }
  currentFromDateValue = '';

  toDateControl = new UntypedFormControl('');
  toDateField = {
    key: 'from_date'
  }
  currentToDateValue = '';

  showDateMismatchError = false;

  // report type
  reportTypeControl = new UntypedFormControl('annual');
  // exchange related variables

  public exchanges: any[] = [];
  public presetExchanges: {[key: string]: any[]} = {};

  exchange_ids = '';
  @Output() filterUpdate = new EventEmitter<any>();

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get(CompanyReportsSettings.BASE_URL +'/get_exchange_presets/').subscribe((exchangePresets: any) => {

      this.presetExchanges = exchangePresets;

      for (let key in this.presetExchanges) {
        if(key === 'Nordic') {
          const initialPreset = {
            key,
            value: this.presetExchanges[key]
          }
          this.addExchange(initialPreset);
        }
      }
    })
  }

  updateFilter(isUpdated: any, control: string) {

    this.showDateMismatchError = false;

    if(isUpdated && control === 'FROM_DATE' && (this.currentFromDateValue !== this.fromDateControl.value)){
      this.currentFromDateValue = this.fromDateControl.value;
    }

    if(isUpdated && control === 'TO_DATE' && (this.currentToDateValue !== this.toDateControl.value)){
      this.currentToDateValue = this.toDateControl.value;
    }
    if(this.currentFromDateValue && this.currentToDateValue ) {
      // validate dates
      const fromDate = moment(this.currentFromDateValue);
      const toDate = moment(this.currentToDateValue);
      if(toDate.diff(fromDate) < 0) {
        // to date is less than from date
        this.showDateMismatchError = true;
        return;
      }
    }

    const filterObject = {
      fromDate: this.currentFromDateValue,
      toDate: this.currentToDateValue,
      reportType: this.reportTypeControl.value,
      exchange_ids: this.exchange_ids
    }
    this.filterUpdate.emit(filterObject);
  }
  refresh(){
    if (this.exchanges.length) {
      this.exchange_ids = this.exchanges.map(e => e.id).join();
    } else {
      this.exchange_ids = '';
    }
    this.updateFilter(true, 'EXCHANGE_IDS')
  }
  addExchange(data: any) {
    // From group
    if (data.hasOwnProperty('value') && Array.isArray(data.value)) {
      const uniqueExchanges = [];

      if (this.addedToExchangeFilter(data)) {
        // toggle remove
        (data.value as Array<any>).forEach((preset) => {
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
          if (givenExchangeInExistingList !== -1) {
            this.exchanges.splice(givenExchangeInExistingList, 1);
          }
        })
        this.refresh();
      } else {
        // toggle add
        (data.value as Array<any>).forEach((preset) => {
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
          if (givenExchangeInExistingList === -1) {
            uniqueExchanges.push(preset);
          }
        })
        if (uniqueExchanges.length > 0) {
          this.exchanges.push(...uniqueExchanges);
          this.refresh();
        }
      }
    } else {
      // From search box
      const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(data.id));
      if (givenExchangeInExistingList === -1) {
        this.exchanges.push(data);
        this.refresh();
      }
    }
  }
  addedToExchangeFilter(preset): boolean {
    if (preset.hasOwnProperty('value') && Array.isArray(preset.value)) {
      const mappedPresetIds = preset.value.map((item) => item.id);
      const mappedExchangesIds = this.exchanges.map((item) => item.id);
      return mappedPresetIds.every(v => mappedExchangesIds.includes(v));
    }
    return false;
  }
  removeExchange(exchange: any) {
    this.exchanges = this.exchanges.filter(e => Number(e.id) !== Number(exchange.id));
    this.refresh();
  }
  removeAllExchanges() {
    this.exchanges = [];
    this.refresh();
  }
}
