<div class="card mb-2" *ngIf="!isLoading()">
    <con-no-data *ngIf="isLocked" count="0" class="locked-report"></con-no-data>
    <con-no-data *ngIf="isCalculated" count="0" class="locked-report calculated"></con-no-data>
    <div class="card-header cursor-pointer" (click)="toggleExpansion()">
        <div class="d-flex justify-content-start">
            <con-icon-by-name [iconName]="entityDescription.getIcon()" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
            <span *ngIf="entityAlreadyExists()">{{ entityDescription.getPrimaryString(entity) }}</span>
            <span *ngIf="!entityAlreadyExists()">New {{ entityDescription.name | prettyCase }}</span>
            <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
            </div>
        </div>
    </div>
    <div class="card-body" [hidden]="!expanded">
        <con-entity-form-content
            [fromDetailPage]="fromDetailPage"
            [reloadEditData]="reloadEditData"
            [fromCalendar]="fromCalendar"
            [fromReports]="fromReports"
            [hidden]="!expanded"
            [entityName]="entityName"
            [entity]="obj"
            [fixedValues]="fixedValues"
            [parentCompany]="parentCompany"
            [removeRelations]="removeRelations"
            [canEdit]="canEdit"
            [canShow]="canShow"
            [canDelete]="canDelete"
            [emptyAfterSave]="emptyAfterSave"
            [except]="except"
            [only]="only"
            [showWhenChanged]="showWhenChanged"
            (afterSave)="onSave($event)"
        >
        </con-entity-form-content>
    </div>
</div>
<con-loader *ngIf="isLoading()"></con-loader>
