<div class="card mb-2">
    <div class="card-header cursor-pointer" (click)="toggleType()">
        <div class="d-flex justify-content-start">
            <span>Missing calendar events</span>
            <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="getMutations()"></con-icon-by-name>
            </div>
        </div> 
        
    </div>
    <!-- <div class="card-header cursor-pointer" (click)="toggleType()">
        <div class="d-flex justify-content-start">
            <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
            </div>
        </div>
    </div> -->
    <con-loader *ngIf="loading"></con-loader>
    <div class="card-body" *ngIf="!loading && error">
        <p class="lead">
            Oops, something went wrong!
        </p>
    </div>
    <div class="card-body" *ngIf="!loading && missingTypes && !missingTypes.length">
        <span class="lead">
            All missing events for this {{ownerType.toLowerCase()}} are created successfully !
        </span>
    </div>
    <div>
        <ng-container *ngIf="show()">
            <div class="card" *ngFor="let t of missingTypes">
                <div class="card-header" (click)="toggleType(t)">
                    <div class="d-flex justify-content-start align-items-center">
                        <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                        {{ t.name }}
                        <div class="card-header-toggle-individual ml-auto">
                            <div class="chip chip-link" *ngIf="t.missing_event">
                                <i class="fa fa-pencil fa-fw"></i>
                                 Edit
                             </div>
                             <con-icon-by-name iconName="angle-right" [mutations]="getMutations(t)"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="showEvent(t)">
                    <con-entity-form-content *ngIf="!t.missing_event"
                        showEditButton=true
                        entityName="CalendarEvent"
                        (showComments)="setCommentFlag($event, t)"
                        [fixedValues]="{ calendar_event_type_id: t.id, owner_id: company.id, owner_type: ownerType, revised_at: now }"
                        (afterSave)="eventCreated($event)"></con-entity-form-content>
                    <con-entity-form-content *ngIf="t.missing_event"
                        showEditButton=true
                        [entity]="t.missing_event"
                        entityName="CalendarEvent"
                        (showComments)="setCommentFlag($event, t)"
                        (afterSave)="eventCreated($event)"
                        [fixedValues]="{ calendar_event_type_id: t.id, owner_id: company.id, owner_type: ownerType, revised_at: now }">
                    </con-entity-form-content>
                        <div class="mt-2"></div>
                        <div  *ngIf="t.missing_event">
                            <div class="alert alert-info clearfix" *ngFor="let comment of t.missing_event.comments">
                                <div class="float-left text-left" *ngIf="!isEditingComment(comment)">
                                    {{ comment.comment }} ({{ comment.language.iso639_2 }})
                                </div>
                                <con-entity-form-content
                                    *ngIf="isEditingComment(comment, t.missing_event)"
                                    entityName="CalendarEventComment"
                                    [entity]="comment"
                                    [fixedValues]="{ calendar_event_id: t.missing_event.id, language_id: comment.language_id }"
                                    (afterSave)="commentUpdated($event, t.missing_event)"
                                    (afterDelete)="commentDeleted($event, t.missing_event)"
                                    toastMessage="Updated successfully!"
                                ></con-entity-form-content>
                                <div class="float-right">
                                    <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment, t.missing_event)"></con-icon-by-name>
                                </div>
                            </div>
                            <div class="alert alert-info" *ngIf="t.missing_event.id">
                                <con-entity-form-content
                                    entityName="CalendarEventComment"
                                    [fixedValues]="{ calendar_event_id: t.missing_event.id }"
                                    [emptyAfterSave]="true"
                                    (afterSave)="commentAdded($event, t.missing_event)"
                                    toastMessage="Added successfully!"
                                >
                                </con-entity-form-content>
                            </div>
                        </div>
                    <p class="lead" *ngIf="!profile.can(profile.getPermissionName('CalendarEvent', 'store'))">
                        You are not allowed to create new company events!
                    </p>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card mb-2">
            <div class="card-header">
                <div class="float-left text-left">
                    {{company.currentEditingEvent ? 'Edit Event' : 'New Event'}}
                </div>
                <div class="float-right cursor-pointer" *ngIf="company.currentEditingEvent" >
                    <div class="chip chip-link add-comment" *ngIf="company.currentEditingEvent" (click)="toggleEdit()">
                        <i class="fa fa-pencil fa-fw"></i>
                         Edit
                     </div>
                    <con-icon-by-name iconName="remove" (click)="clearEditor()"></con-icon-by-name>
                </div>
            </div>
            <div class="card-body" *ngIf="showEvent()">
                        <con-entity-form-content *ngIf="!company.currentEditingEvent" 
                                    [ownerType]="ownerType"    
                                    showEditButton=true
                                    entityName="CalendarEvent"
                                    (showComments)="setCommentFlag($event)"
                                    [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                                    [emptyAfterSave]="true"
                                    (afterSave)="newEventChanged($event)"
                                    toastMessage="Added successfully!"></con-entity-form-content>
                        <con-entity-form-content *ngIf="company.currentEditingEvent" 
                                    [ownerType]="ownerType" 
                                    showEditButton=true
                                    entityName="CalendarEvent"
                                    (showComments)="setCommentFlag($event)"
                                    [entity]="company.currentEditingEvent"
                                    [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                                    [emptyAfterSave]="true"
                                    (afterSave)="newEventChanged($event)"
                                    toastMessage="Updated successfully!"></con-entity-form-content>
                    <div *ngIf="company.currentEditingEvent">
                        <div class="alert alert-info clearfix" *ngFor="let comment of company.currentEditingEvent.comments">
                            <div class="float-left text-left" *ngIf="!isEditingComment(comment,company.currentEditingEvent)">
                                {{ comment.comment }} ({{ comment.language.iso639_2 }})
                            </div>
                            <con-entity-form-content
                                *ngIf="isEditingComment(comment, company.currentEditingEvent)"
                                entityName="CalendarEventComment"
                                [entity]="comment"
                                [fixedValues]="{ calendar_event_id: company.currentEditingEvent.id, language_id: comment.language_id }"
                                (afterSave)="commentUpdated($event, company.currentEditingEvent)"
                                (afterDelete)="commentDeleted($event, company.currentEditingEvent)"
                                toastMessage="Updated successfully!"
                            ></con-entity-form-content>
                            <div class="float-right">
                                <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment, company.currentEditingEvent)"></con-icon-by-name>
                            </div>
                        </div>
                        <div class="alert alert-info" *ngIf="company.currentEditingEvent.id">
                            <con-entity-form-content
                                entityName="CalendarEventComment"
                                [fixedValues]="{ calendar_event_id: company.currentEditingEvent.id }"
                                [emptyAfterSave]="true"
                                (afterSave)="commentAdded($event, company.currentEditingEvent)"
                                toastMessage="Added successfully!"
                            >
                            </con-entity-form-content>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
