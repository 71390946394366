import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InsideComponent } from './inside.component';
import { SettingsComponent } from './settings.component';

import { entitiesRoutes } from './entities/entities.routes';
import { authRoutes } from './auth/auth.routes';
import { powersRoutes } from './powers/powers.routes';
import { dashboardRoutes } from './dashboard/dashboard.routes';
import { staticRoutes } from './static/static.routes';
import { matchRoutes } from './match/match.routes';
import { calendarRoutes } from './calendar/calendar.routes';
import { listsRoutes } from './lists/lists.routes';

import { AuthGuard } from './auth/guards/auth-guard.service';

import { PageNotFoundComponent } from './page-not-found.component';
import { taskManagerRoutes } from './task-manager/task-manager.routes';
import { docProcessRoutes } from './doc-process/doc-process.routes';
import { missingEventsRoutes } from './missing-events/missing-events.routes';
import { marketcapRoutes } from './market-cap/market-cap.routes';
import { shareholdersRoutes } from './shareholders/shareholders.routes';
import { companyReportsRoutes } from './company-reports/company-reports.routes'
import { mozendaRoutes } from './mozenda/mozenda.routes';

const appRoutes: Routes = [
    {
        path: '',
        component: InsideComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'entity',
                children: [...entitiesRoutes]
            },
            {
                path: 'powers',
                children: [...powersRoutes]
            },
            {
                path: 'dashboard',
                children: [...dashboardRoutes]
            },
            {
                path: 'static',
                children: [...staticRoutes]
            },
            {
                path: 'match',
                children: [...matchRoutes]
            },
            {
                path: 'settings',
                component: SettingsComponent
            },
            {
                path: 'calendar',
                children: [...calendarRoutes]
            },
            {
                path: 'company_institution_lists',
                children: [...listsRoutes]
            },
            {
                path: 'task_manager',
                children: [...taskManagerRoutes]
            },
            {
                path: 'doc_process',
                children: [...docProcessRoutes]
            },
            {
                path: 'missing-events',
                children: [...missingEventsRoutes]
            },
            {
                path: 'marketcap',
                children: [...marketcapRoutes]
            },
            {
                path: 'shareholders',
                children: [...shareholdersRoutes]
            },
            {
                path: 'company_reports',
                children: [...companyReportsRoutes]
            },
            {
                path: 'mozenda',
                children: [...mozendaRoutes]
            }
        ]
    },
    ...authRoutes,
    { path: '**', component: PageNotFoundComponent }

];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

}
