<ul class="prev-val-ui">
  <li>
    <div ngbTooltip="" class="pull-right editor-year" *ngIf="balanceSheet?.showPrevious">
      <select [formControl]="dateRangePicker" class="form-control custom-select editor-select" (ngModelChange)="_check()">
        <option *ngFor="let item of balanceSheet?.past_statements" [value]="item.id">
          {{item?.balance_sheet_date | date:'yyyy-MM-dd'}}
        </option>
      </select>
      <div class="pull-right w-60" *ngIf="loadingData">
        <con-loader [showCircularLoder]="true"></con-loader>
      </div>
    </div>
    <div ngbTooltip="" class="pull-right editor-year" *ngIf="balanceSheet?.showPrevious">
      <span class="previous-value-header">{{balanceSheet?.balance_sheet_date | date:'yyyy-MM-dd'}}</span>
    </div>
  </li>
</ul>
<con-balance-sheet-editor [treeData]="balanceSheet.modules"
    [showPrevious]="balanceSheet.showPrevious" [parent]="null"
    [quantity]="balanceSheet.quantity?.multiplier"
    [locked]="locked" [entity]="entity"
    (onValueChanged)="enableActionButtons($event)"
    (onMaxValueReached)="onReachingMaxValue($event)">
</con-balance-sheet-editor>
<con-no-data [count]="balanceSheet?.modules?.length" [message]="noDataMessage"></con-no-data>
<div *ngIf="!processingTree">
    <button type="submit" [disabled]="!activateActionButtons || validationErrors.length>0" class="btn btn-success"
        (click)="saveTree(balanceSheet.modules, balanceSheet.id)">Save</button>
    <button type="reset" [disabled]="!activateActionButtons"
        (click)="revertTree(balanceSheet.modules,balanceSheet.quantity?.multiplier);  activateActionButtons = false;"
        class="btn btn-warning">Revert</button>
</div>
<con-loader *ngIf="processingTree"></con-loader>
