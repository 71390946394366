import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EntitiesService } from '../../../entities/services/entities.service';
import { switchMap } from 'rxjs/operators';
@Component({
    selector: 'con-institution',
    templateUrl: './institution.component.html'
})

export class InstitutionComponent implements OnInit  {
    private loading: boolean;
    public institution: any;

    constructor(private service: EntitiesService,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        this.route.params.pipe(
            switchMap((params: Params) => {
                      this.loading = true;

                      return this.service.getEntityById('Institution', params.id);
                  })
                  )
                  .subscribe(institution => {
                      this.institution = institution;
                      this.loading = false;
                  },
                  err => {
                      if (err.status === 404) {
                          this.router.navigate(['static', 'error'], { skipLocationChange: true });
                      }
                      this.loading = false;
                  });
    }

    public isLoading() {
        return this.loading;
    }

    show (): boolean {
        if (!this.loading && this.institution && this.institution.id) {
            return true;
        } else {
            return false;
        }
    }

}
