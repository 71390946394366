import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './entity-has-dependencies-modal.component.html',
    selector: 'con-entity-has-dependencies-modal'
})

export class EntityHasDependenciesModalComponent {
    @Input() entities: any;

    constructor(public activeModal: NgbActiveModal) {}

    setEntities(entities: any) {
        this.entities = entities;
    }
}
