import { Component, Input, OnChanges } from '@angular/core';

const ERROR_TEMPLATES = {
    required: 'This field is required',
    email: 'Must be a valid email',
    default: 'Something is wrong with this value'
}

@Component({
    selector: 'con-form-errors',
    templateUrl: './form-errors.component.html'
})

export class FormErrorsComponent implements OnChanges {
    @Input() control: any;
    @Input() extra: any;

    public controlsInternal: any;
    ngOnChanges() {
        if (this.isArray(this.control)) {
            this.controlsInternal = this.control;
        } else {
            this.controlsInternal = [ this.control ];
        }
    }
    isArray(arr: any) {
        return Object.prototype.toString.call(arr) === '[object Array]';
    }
    getErrorText(errorName: string, control: any) {
        return ERROR_TEMPLATES[errorName] ? ERROR_TEMPLATES[errorName] : ERROR_TEMPLATES['default']
    }
}
