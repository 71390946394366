<div class="modal-header">
    <h5 class="modal-title">Entity has dependent entities</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('abort')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-2">
        This entity can't be deleted since the following dependencies depends on it:
    </p>
    <div class="card"
         *ngFor="let entityType of entities | keys">
         <div class="card-body">
            <h3>{{ entityType }}</h3>
            <con-entity-primary-alert
            *ngFor="let entity of entities[entityType].entities"
            [entityType]="entityType"
            [entity]="entity">
            </con-entity-primary-alert>
            <p class="mt-2">Total: {{ entities[entityType].count }}</p>
         </div>
    </div>
    <p class="alert alert-danger mt-2">Warning: Force deleting will delete all the above dependencies</p>
    <div class="mb-2 pull-right">
        <button (click)="activeModal.dismiss('abort')" class="btn btn-secondary" type="submit">Cancel</button>
        <button (click)="activeModal.close('proceed')" class="btn btn-primary" type="submit">Force Delete</button>
    </div>
</div>
