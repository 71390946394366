import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { InsideComponent } from './inside.component';

import { EntitiesModule } from './entities/entities.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { RefreshJwtInterceptor } from './auth/services/refresh-jwt.service';
import { AppRoutingModule } from './app-routing.module';
import { PowersModule } from './powers/powers.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { StaticModule } from './static/static.module';
import { MatchModule } from './match/match.module';
import { CalendarModule } from './calendar/calendar.module';
import { ListsModule } from './lists/lists.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { IMaskModule } from 'angular-imask';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { NotAuthorizedComponent } from './not-authorized.component';
import { ToolbarComponent } from './toolbar.component';
import { SettingsComponent } from './settings.component';

import { LayoutService } from './layout.service';

import { DragulaModule } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MomentModule } from 'ngx-moment';

import { AuthSettings } from './settings.class';
import { TaskManagerModule } from './task-manager/task-manager.module';
import { DocProcessModule } from './doc-process/doc-process.module';
import { MissingEventsModule } from './missing-events/missing-events.module';
import { MarketCapModule } from './market-cap/market-cap.module';
import { ShareholdersModule } from './shareholders/shareholders.module';
import { CompanyReportsModule } from './company-reports/company-reports.module';
import { MozendaModule } from './mozenda/mozenda.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import {NgxsModule} from '@ngxs/store';
import {TaskDataEntryState} from './doc-process/sub-modules/universal-data-entry/state-management/states';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    NotAuthorizedComponent,
    InsideComponent,
    ToolbarComponent,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
        config: {
            tokenGetter: AuthSettings.getToken,
            allowedDomains: AuthSettings.WHITELISTED_DOMAINS,
            disallowedRoutes: AuthSettings.BLACKLISTED_ROUTES,
            headerName: AuthSettings.HEADER_NAME,
            authScheme: AuthSettings.HEADER_PREFIX
        }
    }),
    EntitiesModule,
    SharedModule,
    AuthModule,
    PowersModule,
    DashboardModule,
    StaticModule,
    NgbModule,
    DragulaModule.forRoot(),
    AppRoutingModule,
    MomentModule,
    MatchModule,
    CalendarModule,
    ListsModule,
    TaskManagerModule,
    DocProcessModule,
    MissingEventsModule,
    ShareholdersModule,
    CompanyReportsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true
    }),
    IMaskModule,
    MarketCapModule,
    MozendaModule,
    NgxsModule.forRoot([TaskDataEntryState]),
    NgxsReduxDevtoolsPluginModule.forRoot()

  ],
  providers: [
    LayoutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshJwtInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
