import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {CompanyReportsService} from '../../../services/company-reports.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {EntityConfirmModalComponent} from '../../../../entities/components/views/entity-confirm-modal.component';
import {prettyCase} from '../../../../shared/utilities.classes';
import {SendToMlDialogComponent} from '../../../component/send-to-ml-dialog/send-to-ml-dialog.component';
import {take} from 'rxjs/operators';
import {MLSendStatus} from '../../../models/MLSendStatus';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'con-company-report-files-side-menu',
  templateUrl: './company-report-files-side-menu.component.html',
  styleUrls: ['./company-report-files-side-menu.component.scss']
})
export class CompanyReportFilesSideMenuComponent implements OnInit, OnDestroy {

  public companyId: string;
  public hasTaxonomy = false;
  public lockReportFlag: boolean;
  public report: any;
  public lockLoader = false;
  public selectedMenu: string;
  public taxonomyTypes = {
    'income-statement': 'Income-Statement',
    'balance-sheet': 'Balance-Sheet',
    'cash-flow': 'Cash-Flow-Statement',
    'period_kpi': 'Period-KPI',
    'snapshot_kpi': 'Snapshot-KPI'
  }
  private taxonomySub: Subscription;
  private dialogRef = null;
  showMlLoader = false;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private companyService: CompanyReportsService,
              private modalService: NgbModal,
              private toastr: ToastrService,
              private cdr: ChangeDetectorRef,
              @Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.route.firstChild.params.subscribe((data) => {
      this.companyId = data.companyId;
    })
    const url = this.router.url.split('/');
    this.selectedMenu = url[url.length - 1];
    const menuNameLength = this.selectedMenu.indexOf('?');
    if (menuNameLength !== -1) {
      this.selectedMenu = this.selectedMenu.substring(0, menuNameLength);
    }
    this.companyService.hasTaxomnomy.subscribe(taxonomy => {
      this.report.taxonomies = taxonomy;
    });
    this.taxonomySub = this.companyService.selectedReport.subscribe(entity => {
      this.report = entity;
      this.lockReportFlag = this.report.locked;
      if (this.taxonomyTypes[this.selectedMenu]) {
        this.checkTaxonomy(this.selectedMenu, true);
      }
    });
  }


  ngOnDestroy() {
    this.taxonomySub.unsubscribe();
    this.clearToastMessages();
    this.renderer.removeClass(this.document.body, 'cursor-wait');
  }

  backToReports() {
    this.selectedMenu = 'report-overview';
    this.router.navigate(['company_reports', 'company', this.companyId]);
  }

  checkTaxonomy(path: string, redirectFlag: boolean) {
    const type = this.taxonomyTypes[path]
    if (!type) {
      this.selectedMenu = path;
      this.router.navigate([this.companyId, 'report', this.report.id, path], {relativeTo: this.route.parent});
      return;
    }
    let hasTaxonomy = false;
    if (this.report && this.report.taxonomies) {
      this.report.taxonomies.forEach((tax: any) => {
        if (tax.taxonomy_type === type) {
          hasTaxonomy = true;
        }
      });
    }
    if (!hasTaxonomy) {
      const modalRef = this.modalService.open(EntityConfirmModalComponent,
        {
          size: 'md'
        });

      const data = {
        title: 'Taxonomy',
        message: 'Please include a taxonomy to proceed !',
        alert: true
      }

      modalRef.componentInstance.modalData = data;
      modalRef.result.then((result) => {
        if (redirectFlag) {
          this.selectedMenu = 'report-overview';
          this.router.navigate([this.companyId, 'report', this.report.id, 'report-overview'], {relativeTo: this.route.parent});
        }
      }, (reason) => {
        if (redirectFlag) {
          this.selectedMenu = 'report-overview';
          this.router.navigate([this.companyId, 'report', this.report.id, 'report-overview'], {relativeTo: this.route.parent});
        }
      });
    } else {
      this.selectedMenu = path;
      this.router.navigate([this.companyId, 'report', this.report.id, path], {relativeTo: this.route.parent});
    }
  }

  getLockText() {
    let text = 'Lock Report';
    if (this.lockReportFlag) {
      text = 'Unlock report'
    }
    return text;
  }
  disableLockReport() : boolean {
    if(this.report.ml_info.ml_request_status !== null && this.report.ml_info.ml_request_status !== MLSendStatus.FAILED){
      return true;
    }
    return false;
  }
  lockReport() {
    this.lockReportFlag = !this.lockReportFlag;
    const modalRef = this.modalService.open(EntityConfirmModalComponent,
      {
        size: 'md'
      });
    const lock = this.lockReportFlag ? 'lock' : 'unlock';
    const data = {
      title: 'Lock',
      message: 'Are you sure you want to ' + lock + ' this report ?',
      alert: false
    }

    modalRef.componentInstance.modalData = data;
    modalRef.result.then((result) => {
      if(this.report.ml_info.ml_request_status !== null && this.report.ml_info.ml_request_status !== MLSendStatus.FAILED && this.report.ml_info.ml_request_status !== MLSendStatus.CLOSED){
        this.lockReportFlag = !this.lockReportFlag;
        this.lockLoader = false;
        this.toastr.warning('ML data is being processed', 'Report can not be locked');
        return;
      }
      this.lockLoader = true;
      this.companyService.locking = true;
      this.companyService.setReportLock({locked: this.lockReportFlag}, this.report.id)
        .subscribe(entity => {
            this.report = entity;
            this.companyService.selectedReport.next(entity);
            if (this.report.locked) {
              this.toastr.success('Report locked successfully!', 'Company report')
            } else {
              this.toastr.success('Report unlocked successfully!', 'Company report')
            }
            this.companyService.onReportLocked.next(this.report.locked)
            setTimeout(() => {
              this.companyService.locking = false;
              this.lockLoader = false
            }, 10)
          },
          err => {
            if(err.hasOwnProperty('type')){
              if(err.type === "LOCKED_ERROR" && !this.report.locked){
                this.toastr.warning('ML data is being processed', 'ML Error');
              } else {
                this.toastr.warning(err.data.message);
              }
            }
            this.lockReportFlag = !this.lockReportFlag;
            this.companyService.locking = false;
            this.lockLoader = false;
          });
    }, (reason) => {
      this.lockReportFlag = !this.lockReportFlag;
      this.lockLoader = false;
    });
  }

  openSendToMLPopup(): void {
    this.clearToastMessages();
    // validate report files status
    this.companyService.getReportFileStatuses(this.report.id).subscribe((response: any) => {
      // check if report can be send to ml based on status
      if(response.ml_info.ml_request_status !== null && response.ml_info.ml_request_status !== MLSendStatus.FAILED) {
        this.toastr.warning('Invalid Request. A request already sent to ML');
        return;
      }
      // validate each items for ml processing
      if(!response.balanceSheets || !response.cashFlows || !response.companyReportFiles || !response.incomeStatements  || !response.periodKpis || !response.snapshotKpis || response.locked) {

        let errorStr = `<ul class='ml-message-list'>`;
        // check report is locked
        if(response.locked) {
          errorStr += `<li> Report is locked </li>`
        }
        // check at least one report file is added
        if(!response.companyReportFiles) {
          errorStr += `<li> Minimum one file of type Report is required </li>`
        }
        if(!response.balanceSheets) {
          errorStr += `<li> No balance sheet attached </li>`
        }
        if(!response.cashFlows) {
          errorStr += `<li> No cash flow attached </li>`
        }
        if(!response.incomeStatements) {
          errorStr += `<li> No income statement attached </li>`
        }
        if(!response.periodKpis) {
          errorStr += `<li> No period kpi attached </li>`
        }
        if(!response.snapshotKpis) {
          errorStr += `<li> No snapshot kpi attached </li>`
        }
        errorStr += `</ul>`;
        this.toastr.warning(errorStr, 'Unable to send to ML', {enableHtml: true});

        return;
      }

      // get data of custom data of report files

      this.companyService.getCompanyReportFilesData(this.report.id, 1).subscribe((reportFilesListData: any) => {
        if(this.dialogRef === null) {
          this.dialogRef = this.modalService.open(SendToMlDialogComponent,
            {
              size: 'lg',
            });
        } else {
          return;
        }
        const data = {
          title: 'Send To ML',
          // make below code more generic to support future handling of cases other than created & null
          message: !!response.ml_info.ml_request_status ? 'Are you sure you want to send this report to ML again?' : 'Are you sure you want to send this report to ML?',
          alert: false,
          reportId: this.report.id,
          mlInfo: response.ml_info,
          numberOfAvailableReportFiles: reportFilesListData.total,
          reportFilesData: reportFilesListData
        }

        this.dialogRef.componentInstance.modalData = data;
        this.companyService.setSelectedReportFile(null);
        this.dialogRef.result.then(() => {
            this.dialogRef = null;
            this.showMlLoader = true;
            this.renderer.addClass(this.document.body, 'cursor-wait');
            this.companyService.sendToML(this.report.id).subscribe((sendToMLResponse) => {
              this.showMlLoader = false;
              this.renderer.removeClass(this.document.body, 'cursor-wait');
              if (sendToMLResponse.hasOwnProperty('request_status')) {
                this.report.ml_info = {company_report_file: this.companyService.getSelectedReportFile(), ml_request_status: sendToMLResponse.request_status};
                this.companyService.setMLCurrentStatus(this.report.ml_info);
                this.companyService.selectedReport.next(this.report);
                this.toastr.clear();
                this.toastr.success(sendToMLResponse.message);
              }
            }, (err) => {
              this.showMlLoader = false;
              this.renderer.removeClass(this.document.body, 'cursor-wait');
              if (Array.isArray(err.data)) {
                let errorStr = `<ul class='ml-message-list'>`;
                err.data.forEach((errorMessage) => {
                  errorStr += `<li> ${errorMessage} </li>`
                })
                errorStr += `</ul>`;
                this.toastr.warning(errorStr, 'Unable to send to ML', {enableHtml: true});
              } else {
                this.toastr.error(
                  'Sorry, some error occurred',
                  'Send To ML'
                );
              }
            })
          },
          (reason) => {
            console.error(reason);
            this.dialogRef = null;
          })
      });

    },(error) => {
      console.error(error);
    });
  }
  clearToastMessages() {
    this.toastr.toasts.forEach(toast => {this.toastr.remove(toast.toastId)})
    this.toastr.clear();
  }
}
