
import {of as observableOf,  Observable } from 'rxjs';

import { switchMap, tap, catchError, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { EntitiesService, EntityDescription } from '../../services/entities.service';



@Component({
    selector: 'con-entity-search-in-relation',
    templateUrl: './entity-search-in-relation.component.html'
})

export class EntitySearchInRelationComponent implements OnChanges {
    @Input() parentEntityName: string;
    @Input() parentId: number;
    @Input() relationName: string;
    @Input() fixedSearchParams: any = {};
    @Input() disabled = false;

    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

    private entityDescription: EntityDescription;
    public relatedEntityDescription: EntityDescription;
    private relation: any;
    public loading: boolean;

    public model: any;
    public searching = false;
    public searchFailed = false;
    private totalResult = 0;
    private showing = 0;

    constructor(private service: EntitiesService) {}

    ngOnChanges() {
        this.loading = true;
        this.service.getEntityDescriptionByEntityName(this.parentEntityName).pipe(
                    switchMap(entityDescription => {
                        this.entityDescription = entityDescription;
                        this.relation = this.entityDescription.getAllRelations().find(r => r.name === this.relationName);
                        return this.service.getEntityDescriptionByEntityName(this.relation.model);
                    }))
                    .subscribe(entityDescription => {
                        this.loading = false;
                        this.relatedEntityDescription = entityDescription;
                    });
    }

    public search = (text$: Observable<string>) => {
        return text$
          .pipe(debounceTime(400))
          .pipe(distinctUntilChanged())
          .pipe(tap(() => this.searching = true))
          .pipe(switchMap(term => {
              const terms = this.fixedSearchParams;
              terms[''] = term;
              const obs$ = this.service.searchRelation(this.parentEntityName,
                                                    this.parentId,
                                                    this.relationName,
                                                    terms);
              return obs$.pipe(map(entities => {
                                this.totalResult = entities.total;
                                this.showing = entities.data.length;
                                return entities.data;
                            }))
                            .pipe(tap(() => {
                                this.searchFailed = false;
                                this.searching = false;
                            }))
                            .pipe(catchError(() => {
                              this.searchFailed = true;
                              return observableOf([]);
                          }));
          }));
    }

    private formatter = (entity: any) => {
        return this.relatedEntityDescription.getPrimaryString(entity);
    }

    entitySelected(model: any) {
        if (model && model.id) {
            this.onSelect.emit(model);
            this.model = '';
        }
    }
}
