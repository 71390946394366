<div class="row">
    <div class="col-2">
        <button class="btn btn-sm btn-outline-info" (click)="refresh()"><con-icon-by-name iconName="refresh"></con-icon-by-name></button>
    </div>
    <div class="col-10">
        <div class="form-inline justify-content-end">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Timezone</span></label>
            <ng-select class="date-picker" [clearable]="false" [items]="availableTimezones" [formControl]="timezoneControl"></ng-select>
        </div>
    </div>
</div>
<div class="calendar">
    <div class="days-of-week">
        <span class="week-block"></span>
        <span class="day-block" *ngFor="let day of ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']">{{ day }}</span>
    </div>
    <div class="row" *ngIf="loading">
        <div class="col-12">
            <con-loader></con-loader>
        </div>
    </div>
    <ng-container *ngIf="!loading">
        <div class="days-of-month" *ngFor="let week of weeks">
            <span class="week-block">
                <span class="week-number">
                    <span class="week-text">Week </span>{{ week[0].date.isoWeek() }}
                </span>
            </span>
            <span class="day-block" *ngFor="let date of week" [ngClass]="{ 'today': isToday(date.date) }">
                <div class="event-block">
                    <div class="alert alert-info clearfix" *ngFor="let event of date.events">
                        <div class="float-left text-left">
                            <small>
                                <p class="alert-link" *ngIf="showEventWithoutLink(event)">{{ event.owner.name }}</p>
                                <a class="alert-link" *ngIf="showCompanyEventWithLink(event)" [routerLink]="[ 'company', event.owner_id ]">{{
                                    event.owner.name }}</a>
                                <a class="alert-link" *ngIf="showInstitutionEventWithLink(event)" [routerLink]="[ 'institution', event.owner_id ]">{{
                                    event.owner.name }}</a>
                                <br> {{ event.calendar_event_type.name }} ({{ event.from_date | amDateFormat:'HH:mm' }}<span
                              *ngIf="event.to_date != null"> - <span *ngIf="!event.to_date.isSame(event.from_date, 'day')">{{
                              event.to_date | amDateFormat:'yyyy-MM-DD' }}</span>{{ event.to_date | amDateFormat:'HH:mm'
                              }}
                                </span>)
                                <em *ngFor="let comment of event.comments">
                                    <br>{{ comment.comment }} ({{ comment.language.iso639_2 }})
                                </em>
                            </small>
                        </div>
                        <div class="float-right">
                            <con-icon-by-name iconName="pencil" class="cursor-pointer" *ngIf="showEditor && event.direkt_id === null" (click)="putInEditor(event, editEvent)"></con-icon-by-name>
                            <con-icon-by-name iconName="bolt" *ngIf="event.direkt_id !== null" ngbTooltip="Imported from Direkt" container="body" placement="left"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <span class="number" [ngClass]="{'number-muted': !isHighlightedMonth(date.date) }">{{ date.date | amDateFormat:'DD'}}</span>
            </span>
        </div>
    </ng-container>
</div>
<div class="row" #editEvent>
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <div class="float-left text-left">
                    {{currentEditingEvent ? 'Edit Event' : 'New Event'}}
                </div>
                <div class="float-right cursor-pointer">
                    <con-icon-by-name iconName="remove" (click)="clearEditor()"></con-icon-by-name>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-4">
                        <con-entity-form-content *ngIf="!currentEditingEvent"
                                    entityName="CalendarEvent"
                                    [fixedValues]="{ revised_at: now }"
                                    [except]="['direkt_id']"
                                    [emptyAfterSave]="true"
                                    (afterSave)="eventChanged($event)"
                                    (afterDelete)="eventDeleted($event)"
                                    toastMessage="Added successfully!"></con-entity-form-content>
                        <con-entity-form-content *ngIf="currentEditingEvent"
                                    entityName="CalendarEvent"
                                    [entity]="currentEditingEvent"
                                    [fixedValues]="{ revised_at: now }"
                                    [except]="['direkt_id']"
                                    [emptyAfterSave]="true"
                                    (afterSave)="eventChanged($event)"
                                    (afterDelete)="eventDeleted($event)"
                                    toastMessage="Updated successfully!"></con-entity-form-content>
                    </div>
                    <div class="col-8" *ngIf="currentEditingEvent">
                        <div class="alert alert-info clearfix" *ngFor="let comment of currentEditingEvent.comments">
                            <div class="float-left text-left" *ngIf="!isEditingComment(comment)">
                                {{ comment.comment }} ({{ comment.language.iso639_2 }})
                            </div>
                            <con-entity-form-content
                                *ngIf="isEditingComment(comment)"
                                entityName="CalendarEventComment"
                                [entity]="comment"
                                [fixedValues]="{ calendar_event_id: currentEditingEvent.id, language_id: comment.language_id }"
                                (afterSave)="commentUpdated($event)"
                                (afterDelete)="commentDeleted($event)"
                                toastMessage="Updated successfully!"
                            ></con-entity-form-content>
                            <div class="float-right">
                                <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment)"></con-icon-by-name>
                            </div>
                        </div>
                        <div class="alert alert-info" *ngIf="currentEditingEvent.id">
                            <con-entity-form-content
                                entityName="CalendarEventComment"
                                [fixedValues]="{ calendar_event_id: currentEditingEvent.id }"
                                [emptyAfterSave]="true"
                                (afterSave)="commentAdded($event)"
                                toastMessage="Added successfully!"
                            >
                            </con-entity-form-content>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
