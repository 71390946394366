import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CompanyReportsSettings } from 'app/settings.class';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import * as moment from 'moment';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import {EntitiesService} from '../../../entities/services/entities.service';



@Component({
  selector: 'con-balance-sheet',
  templateUrl: './balance-sheet.component.html'
})
export class BalanceSheetComponent implements OnInit, OnDestroy {

  @Input() entity: any = {};
  public loading = true;
  public routeSubscription: Subscription;
  public companyReport: any;
  public id: number;
  public noDataMessage = 'No balance sheets to display';
  public entityCompanyReport: any;
  public previousYearValues: any = {};
  public previousValueSource: any = {};
  public MLSendStatus = MLSendStatus;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public entitiesService: EntitiesService,
    public companyService: CompanyReportsService) { }

  ngOnInit() {
    this.entityCompanyReport = {
      company_report: {
        company: {
          name: this.entity.company.name + ' - ' +
            moment(this.entity.report_date).format('yyyy-MM-DD')
        },
        id: this.entity.id
      },
      company_report_id: this.entity.id
    };
    this.companyService.selectedReport.subscribe(entity => {
      this.companyReport.data.forEach((balance) => {
        balance.locked = entity.locked;
        if (balance.company_report) {
          balance.company_report = entity.locked;
        }
      });
    });
    this.routeSubscription = this.route.params.subscribe(
      (params: Params) => {
        this.id = params.id;
      });
    this.loadPage();
  }
  loadPage() {
    this.loading = true;
    this.http.get(CompanyReportsSettings.BASE_URL + '/' + this.id + '/balance_sheets')
      .subscribe(companyReport => {
        this.companyReport = companyReport;
        if (this.companyReport.data.length) {
          this.companyReport.data.forEach((balance, index) => {
            if (index === 0) {
              this.getBalanceSheetData(balance);
            } else {
              balance.hide = true;
            }
          });
        }
        this.loading = false;
      },
        err => {
          if (err.status === 404) {
            this.router.navigate(['balance-sheet', 'error'], { skipLocationChange: true });
          }
          this.loading = false;
        });
  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
  public toggleType(event?) {
    event.hide = !event.hide;
  }
  getDisplayName(balance?: any) {
    let displayName = this.entity.company.name;
    if (balance) {
      displayName += ' / '
        + this.entity.report_type
        + ', ' + moment(this.entity.report_date).format('yyyy-MM-DD') + '/ Balance sheet '
        + ' - '
        + moment(balance.balance_sheet_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  getDisplayTitle() {
    let displayName = this.entity && this.entity.company ? this.entity.company.name : '';
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  getMutations(event) {
    return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }
  balanceSheetCreated(balanceSheet) {
    this.companyReport.data.unshift(balanceSheet);
    this.getBalanceSheetData(balanceSheet);
  }
  public getBalanceSheetData(balance: any) {
    if (balance && balance.balanceData) {
      return;
    }
    balance.loading = true;
    this.http.get(CompanyReportsSettings.BASE_URL + '/' + 'balance_sheet/' + balance.id)
      .subscribe(sheet => {
        this.previousYearValues = {};
        this.previousValueSource = {};
        this.companyService.formatPreviousYearData(sheet,this.previousYearValues,this.previousValueSource);
        balance.balanceData = sheet;
        balance.loading = false;
      },
        err => {
          if (err.status === 404) {
            this.router.navigate(['balance-sheet', 'error'], { skipLocationChange: true });
          }
          balance.loading = false;
        });
  }

  getBSTitle(balancedData: any) {
    if (!balancedData) {
      return;
    }
    let title = '';
    if (balancedData && balancedData.quantity && balancedData.quantity.name) {
      title = title + balancedData.quantity.name;
    }
    if (this.entity && this.entity.currency && this.entity.currency.name) {
      title = title + ', ' + this.entity.currency.name;
    }
    if (balancedData && balancedData.unit && balancedData.unit.name) {
      title = title +  ', (' + balancedData.unit.name + ')';
    }
    return title;
  }
  balanceSheetUpdated(balanceSheet, index) {
    this.companyReport.data[index] = balanceSheet;
    this.getBalanceSheetData(this.companyReport.data[index]);
  }
  get settingLock () {
    return this.companyService.locking;
  }
  appendPreviewUrl(data: any, balanceSheet) {
    balanceSheet.previewUrl = data;
  }
  setPreviewMLReportUrlEmpty(balanceSheet, closeEvent: boolean) {
    if(closeEvent) {
      balanceSheet.previewUrl = null;
    }
  }
}
