<span class="badge badge-light badge-time">
    <con-icon-by-name
        iconName="clock-o"
        placement="bottom"
        [ngbTooltip]="timezoneControl.value"
        (click)="toggleTimezoneEditor()"
        >
    </con-icon-by-name>
    <span *ngIf="!isEditingTimezone">{{ formattedDate }}</span>
    <div class="form-inline" *ngIf="isEditingTimezone">
        <ng-select class="date-picker" [clearable]="false" [items]="availableTimezones" [formControl]="timezoneControl"></ng-select>
    </div>
</span>



