import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { ApiSettings, StaticSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { StaticService } from '../services/static.service';
import { TranslationService } from 'app/utility/services/translation.service';
import {UntypedFormControl} from '@angular/forms';
import {take} from 'rxjs/operators';

@Component({
    selector: 'con-static-missing',
    templateUrl: './static-missing.component.html',
    styles: [`.missing-title-positioning{width:100px; float: left;}` , `.fixed-height-container { height: 60px;}`, `.missing-select-positioning{width:170px; float: left; margin-top: 0px;}`]
})

export class StaticMissingComponent implements OnInit, OnDestroy {

    public missing: any;
    public noDataMessage = 'No data to display.';
    public loading = true;
    dataUnderATab = {}
    tabName = 'all';
    subscription: any;
    missingFilter = new UntypedFormControl();
    filterExpanded = true;
    updatedFilterData: any;
    initialized = false;
    sectors: any;
    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService,
                private staticService: StaticService,
                private transService: TranslationService) {}

    ngOnInit(): void {
      this.staticService.getSectorEndpoint().subscribe(sectors => {
        this.sectors = sectors;
      })
    }

    initPage() {
      this.missingFilter.setValue(this.staticService.getMissingFilter());
      this.loadPage(1);
      this.subscription = this.transService.onTranslationSaved.subscribe(() => {
          this.resetFetchedObject();
          this.loadPage(1);
      })
    }

    loadPage(pageNumber: number) {
        this.loading = true;
        this.countService.setIsLoading(true);
        const url = this.getEndpoint(this.tabName);
        this.dataUnderATab[this.getActiveTab()] = [];
        const customParams = {
          page: String(pageNumber),
          exchange_ids: this.updatedFilterData,
          list_filter: this.missingFilter.value
        }
        const onlyPageParam = {
          page: String(pageNumber),
          exchange_ids: this.updatedFilterData
        }
        this.http.get<any>(url,
            {
                    params: (this.getActiveTab() === 'all' || this.getActiveTab() === 'company data') && (this.missingFilter.value !== '') ? customParams : onlyPageParam
                    })
                 .subscribe(res => {
                     this.countService.setCount('missing', res.total);
                     this.missing = res;
                     this.dataUnderATab[this.getActiveTab()] = res;
                     this.loading = false;
                     this.initialized = true;
                     this.countService.setIsLoading(false);
                 }, err => {
                     this.loading = false;
                     this.initialized = true;
                     this.countService.setIsLoading(false);
                     if (err.isPermissionError()) {
                         this.router.navigate(['static', 'auth'], { skipLocationChange: true });
                     }
                 });
    }

    removeFromList(company: any) {
        this.resetFetchedObject()
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.initPage();
        }
    }
    resetFetchedObject() {
        if (this.tabName === 'all') {
            this.dataUnderATab = {}
        } else {
            this.dataUnderATab['all'] = null
        }
    }

    updateInList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        company.positions = this.missing.data[indx].positions;
        company.required_languages = this.missing.data[indx].required_languages;
        company.descriptions = this.missing.data[indx].descriptions;
        this.missing.data[indx] = company;
        this.resetFetchedObject()
    }

    whenFixed(company: any) {
        this.removeFromList(company);
        // set count based api response
        const url = this.getEndpoint(this.tabName);
        // check
        const params = {
          list_filter: this.missingFilter.value
        }
        if (this.getActiveTab() === 'all' || this.getActiveTab() === 'company data') {
          this.countService.setIsLoading(true);
          this.http.get<any>(url, { params }).pipe(take(1)).subscribe(res => {
            this.countService.setCount('missing', res.total);
            this.countService.setIsLoading(false);
          }, (error) => {
            console.error(error);
            this.countService.setIsLoading(false);
          })
        } else {
          this.countService.setIsLoading(true);
          this.http.get<any>(url).pipe(take(1)).subscribe(res => {
            this.countService.setCount('missing', res.total);
            this.countService.setIsLoading(false);
          }, (error) => {
            console.error(error);
            this.countService.setIsLoading(false);
          })
        }
    }

    onTabSelected(tabName) {
      if(this.initialized) {
        this.tabName = tabName;
        this.loadPage(1);
      }
    }

    getActiveTab(): string {
        return this.tabName ? this.tabName : 'all';
    }

    getEndpoint(tabName: any) {
        return this.staticService.getEndpoint(tabName, 'static')
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

  onUpdateMissingFilter() {
    if(this.initialized) {
      this.staticService.setMissingFilter(this.missingFilter.value);
      this.loadPage(1);
    }
  }

  canShowMissingFilterDropdown() {
    return this.tabName === 'all' ||  this.tabName === 'company data';
  }

  updateFilterConfiguration(filterUpdatedData: any) {
    this.updatedFilterData = filterUpdatedData.exchange_ids;
    this.initPage();
  }
}
