import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiSettings, CompanyReportsSettings} from 'app/settings.class';
import {HttpClient} from '@angular/common/http';

export interface RecentList {
  name: string;
  id: number
}

@Injectable({
  providedIn: 'root'
})
export class CompanyReportsService {
  private reportSubject: Subject<any> = new Subject;
  private reports: any[];
  public locking = false;
  public hasTaxomnomy: Subject<boolean> = new Subject();
  public selectedReport: Subject<any> = new Subject();
  public recentSearchUpdated: Subject<any> = new Subject();
  public onReportLocked: Subject<boolean> = new Subject();
  private mlStatus: any;
  private selectedReportFile = null;
  constructor(private http: HttpClient) { }


  setReports(reports: any[]) {
    this.reports = reports;
    this.reportSubject.next(reports);
  }

  getReports(refresh?: boolean, companyId?: number) {
    if ((refresh || !this.reportSubject) && companyId) {
      return this.http.get(CompanyReportsSettings.BASE_URL + '/' + CompanyReportsSettings.COMPANY_ENDPOINT + '/' + companyId);
    }
    return this.reportSubject;
  }
  getCompanyData(id: number): Observable<any> {
    return this.http.get(ApiSettings.BASE_URL + '/' + CompanyReportsSettings.COMPANY_ENDPOINT + '/' + id);
  }

  saveEditordata(editorData: any, isId: number, reportName: string) {
    return this.http.post(CompanyReportsSettings.BASE_URL + '/' + reportName + '/' + isId + '/store_editor', editorData);
  }

  setReportLock(lockData: any, reportId) {
    return this.http.put(CompanyReportsSettings.BASE_URL + '/' + reportId, lockData);
  }

  setRecent(lsName, list: RecentList) {
    try {
      let currentData = JSON.parse(localStorage.getItem(lsName));
      if (currentData && currentData.length) {
        if (currentData.filter(data => data.id === list.id).length === 0) {
          currentData.unshift(list);
        }
      } else {
        currentData = [list];
      }
      currentData.splice(5, currentData.length);
      localStorage.setItem(lsName, JSON.stringify(currentData));
      this.recentSearchUpdated.next(lsName);
    } catch (e) {
      console.log('setRecent : error', e);
      return;
    }
  }

  removeFromRecent(lsName, list: RecentList, remove?: boolean) {
    try {
      const currentData = JSON.parse(localStorage.getItem(lsName));
      if (currentData && currentData.length) {
        const index = currentData.findIndex(data => data.id === list.id);
        if (index > -1) {
          currentData.splice(index, 1);
        }
      }
      localStorage.setItem(lsName, JSON.stringify(currentData));
      this.recentSearchUpdated.next(lsName);
    } catch (e) {
      console.log('removeFromRecent : error', e);
      return;
    }
  }

  getRecent(lsName: string) {
    try {
      const currentData = JSON.parse(localStorage.getItem(lsName));
      if (currentData && currentData.length) {
        return currentData;
      } else {
        return [];
      }
    } catch (e) {
      console.log('getRecent : error', e);
      return [];
    }
  }

  // get missing reports
  getMissingReports(filterParams: any): Observable<any> {
    return this.http.get(CompanyReportsSettings.MISSING_REPORTS_ENDPOINT , {
      params: filterParams
    })
  }


  // get reports which not locked
  getNonLockedReports(pageNumber: number): Observable<any> {
    return this.http.get(CompanyReportsSettings.BASE_URL + `?q:locked=f:false&page=${pageNumber}&order_by=updated_at`)
  }
  // get check page reports
  getCheckReports(filterParams: any): Observable<any> {
    return this.http.get(CompanyReportsSettings.BASE_URL + '/check', {
      params: filterParams
    })
  }
  // activate send to ml function
  sendToML(reportId): Observable<any > {
    return this.http.post(`${CompanyReportsSettings.BASE_URL}/send_report_instance_to_ml/${reportId}`,{
      report_file_id:this.selectedReportFile.id
    });
  }
  // set ml status
  setMLCurrentStatus(status) {
    this.mlStatus = status;
  }
  // get ml status
  mlCurrentStatus(): any {
    return this.mlStatus?.ml_request_status;
  }

  // set selected report file for ml
  setSelectedReportFile(data) {
    this.selectedReportFile = data;
  }
  // get selected report file for ml
  getSelectedReportFile() {
    return this.selectedReportFile;
  }

  // get company report file
  getCompanyReportFile(reportFileId: number){
    return this.http.get(`${ApiSettings.BASE_URL}/company_report_file/${reportFileId}`);
  }

  // get company report files statuses based on report id
  getReportFileStatuses(reportId: number) {
    return this.http.get(`${CompanyReportsSettings.BASE_URL}/validate_report_for_ml/${reportId}`);
  }
  // past statement
  getIncomeStatementById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/income_statement/${id}/`)
  }
  getBalanceSheetById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/balance_sheet/${id}/`)
  }
  // past balance sheet
  getCashFlowById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/cash_flow/${id}/`)
  }
  // past period kpi
  getPeriodKpiById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/period_kpi/${id}/`)
  }
  // past snapshot kpi
  getSnapshotKpiById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/snapshot_kpi/${id}/`)
  }

  formatPreviousYearData(data: any, previousYearValues, previousValueSource) {
    if (data.past_modules && data.past_modules.length > 1) {
      this.formatPreviousYearValue(data.past_modules,previousYearValues, previousValueSource);
      this.setPreviousYearValue(data.modules,previousYearValues, previousValueSource);
      data.showPrevious = true;
    } else {
      data.showPrevious = false;
    }
  }
  formatPreviousYearValue(past_moduleList, previousYearValues, previousValueSource) {
    for (let index = 0; index < past_moduleList.length; index++) {
      previousYearValues[past_moduleList[index].module_id] = past_moduleList[index].value_rep;
      previousValueSource[past_moduleList[index].module_id] =  past_moduleList[index].value_source;
      if (past_moduleList[index].children && past_moduleList[index].children.length > 0) {
        this.formatPreviousYearValue(past_moduleList[index].children, previousYearValues, previousValueSource)
      }
    }
  }
  setPreviousYearValue(moduleList, previousYearValues, previousValueSource) {
    for (let index = 0; index < moduleList.length; index++) {
      moduleList[index].prev_year_value = previousYearValues[moduleList[index].module_id];
      moduleList[index].prev_value_source = previousValueSource[moduleList[index].module_id];
      if (moduleList[index].children && moduleList[index].children.length > 0) {
        this.setPreviousYearValue(moduleList[index].children, previousYearValues, previousValueSource)
      }
    }
  }
  getCompanyReportFilesData(reportFileId: number, pageNumber: number){
    return this.http.get(`${CompanyReportsSettings.BASE_URL}/report_files_for_ml/${reportFileId}`, {
      params: {
        page:pageNumber,
        order_by:'updated_at'
      }});
  }
  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }
  // get all statements
  getAllStatement(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/all-statements/${id}/`)
  }
}

