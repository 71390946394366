import {distinct, map} from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';


import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { DateConverterService } from '../services/date-converter.service';

import * as moment from 'moment-timezone';

import { EntitiesService } from '../../entities/services/entities.service';
import { Subscription } from 'rxjs';
@Component({
    selector: 'con-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss']
})

export class DateInputComponent implements OnChanges, OnInit, OnDestroy {
    @Input() control: UntypedFormControl;
    @Input() field: any;
    @Input() entityForm: UntypedFormGroup;

    private defaultDate: any;
    public formDate: UntypedFormGroup;
    public currentDate = '';
    public editing = false;
    private windowOpen = false;
    public availableTimezones: any = moment.tz.names();
    public availableMonths: any = moment.months();
    private fromDateSubscription: Subscription;

    constructor(private dateConverter: DateConverterService, private service: EntitiesService) {}

    ngOnInit() {
        this.service.calendarEditingSubject.subscribe(entity => {
            if (entity === 'CalendarEvent') {
                this.editing = false;
            }
        });
    }
    ngOnDestroy() {
        if (this.fromDateSubscription) {
            this.fromDateSubscription.unsubscribe()
        }
    }
    ngOnChanges() {
        const currentTimezone = moment.tz.guess();
        if (this.control.value !== '') {
            this.defaultDate = this.dateConverter.fromEntityString(this.control.value);
        } else {
            this.defaultDate = moment();
        }

        this.formDate = new UntypedFormGroup({
            year: new UntypedFormControl(this.defaultDate.year()),
            month: new UntypedFormControl(this.defaultDate.format('MMMM')),
            date: new UntypedFormControl(this.defaultDate.date()),
            hour: new UntypedFormControl(this.defaultDate.hour()),
            minute: new UntypedFormControl(this.defaultDate.minute()),
            second: new UntypedFormControl(this.defaultDate.second()),
            timezone: new UntypedFormControl(currentTimezone)
        }, {
            updateOn: 'blur'
        });

        this.dateUpdated()
            .subscribe(
                res => {
                    if (this.field.key === 'to_date' && this.windowOpen) {
                        this.windowOpen = false;
                        const from_date = this.entityForm.get('from_date').value;
                        if (from_date && from_date !== this.control.value) {
                            this.control.patchValue(from_date);
                            this.currentDate = from_date;
                            this.control.markAsDirty();
                            this.control.markAsTouched();
                            const timeZone = this.formDate.controls['timezone'].value;
                            this.defaultDate = this.dateConverter.fromEntityString(from_date, timeZone);
                            this.formDate.patchValue({
                                'year': this.defaultDate.year(),
                                'month': this.defaultDate.format('MMMM'),
                                'date': this.defaultDate.date(),
                                'hour': this.defaultDate.hour(),
                                'minute': this.defaultDate.minute(),
                                'second': this.defaultDate.second(),
                                'timezone': this.defaultDate._z.name
                            })
                        }
                        return;
                    }
                    if (!res.error) {
                        this.control.patchValue(res.date);
                        this.currentDate = res.date;
                        this.control.markAsDirty();
                        this.control.markAsTouched();
                    } else {
                        this.control.setErrors({
                            'dateError': true
                        });
                    }
                }
            );
    }

    dateSelected() {
        return this.control.value !== null && this.control.value !== '';
    }

    toggleEdit() {
        this.editing = !this.editing;
        this.windowOpen = this.editing ? true : false;
        this.formDate.controls['year'].patchValue(this.formDate.controls['year'].value);
    }

    reset() {
        this.editing = false;
        this.control.patchValue(null);
        this.control.markAsDirty();
        this.control.markAsTouched();
    }

    dateUpdated() {
        return  this.formDate
                .valueChanges.pipe(
                map(date => {
                    try {
                        const d = moment()
                                .tz(date.timezone);
                        const done = false;

                        ['year',
                        'month',
                        'date',
                        'hour',
                        'minute',
                        'second'].forEach(key => {
                            if (date[key] === null) {
                                throw new TypeError(key + ' is not valid');
                            }
                            d.set(key, date[key]);
                        });

                        return {
                            'error': false,
                            'date': this.dateConverter.toEntityString(d)
                        }

                    } catch (e) {
                        return {
                            'error': true,
                            'date': null
                        }
                    }
                }),
                distinct(), );
    }

    setDate(value, type){
        const from_date_val =  this.entityForm.get('from_date').value;
        let from_date;
        if (from_date_val) {
            from_date = moment(from_date_val);
        } else {
            from_date = moment();
        }
        const to_date = moment(from_date).add(value, type)
        this.control.patchValue(to_date.format('yyyy-MM-DD'));
        this.formDate.patchValue({
            'year': to_date.year(),
            'month': to_date.format('MMMM'),
            'date': to_date.date(),
            'hour': to_date.hour(),
            'minute': to_date.minute(),
            'second': to_date.second()
        })
    }

    isToDate() {
        if (this.field.key === 'to_date') {
            return true;
        }
        return false;
    }
    markControlAsDirty() {
         this.control.markAsDirty();
    }
}
