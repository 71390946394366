<div class="progress-loader">
    <div class="indeterminate"></div>
</div>

<div class="circular-loader-container" *ngIf="showCircularLoder">
    <div class="circular-loader">
       <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
        </svg>
    </div>
</div>