import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class ValidatorConversionService {
    public getValidatorByString(rule: string): any {
        const validator = rule.split(':');
        switch (validator[0]) {
            case 'required':
                return Validators.required;
            case 'email':
                return Validators.email;
            default:
                return false;
        }
    }
}
