<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <div class="page-title-icon">
                    <con-icon-by-name iconName="calendar"></con-icon-by-name>
                </div>
                <h3 class="mb-0">Calendar</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card mb-2">
                <div class="card-header">
                    Go to company
                </div>
                <div class="card-body">
                    <con-entity-search-in-relation parentEntityName="CompanyTier" [parentId]="tierId" relationName="companies" [disabled]="!profile.can(profile.getPermissionName('CompanyTier', 'show', 'companies'))"
                        (onSelect)="navigateToCompany($event)"></con-entity-search-in-relation>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card mb-2">
                <div class="card-header">
                    Go to institution
                </div>
                <div class="card-body">
                    <con-entity-search-in-relation parentEntityName="InstitutionTier" [parentId]="institutionTierId" relationName="institutions" [disabled]="!profile.can(profile.getPermissionName('InstitutionTier', 'show', 'institutions'))"
                        (onSelect)="navigateToInstitution($event)"></con-entity-search-in-relation>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    Month explorer
                </div>
                <div class="card-body">
                    <con-month-explorer></con-month-explorer>
                </div>
            </div>
        </div>
    </div>
</div>