import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import {Observable, of as observableOf} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ApiSettings} from '../../settings.class';
import {FileTokenService} from '../../auth/services/file-token.service';

@Component({
  selector: 'con-custom-search-select',
  templateUrl: './custom-search-select.component.html',
  styleUrls: ['./custom-search-select.component.scss']
})
export class CustomSearchSelectComponent {

  @Input() fixedSearchParams: any = {};
  @Input() id: number;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() showDismiss: Boolean = false;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;

  public model: any;
  public searching = false;
  public searchFailed = false;
  private totalResult = 0;
  private showing = 0;

  public dismiss() {
    this.onDismiss.next();
  }
  constructor(private http:HttpClient, private tokenService: FileTokenService) {
  }

  public search = (text$: Observable<string>) => {
    return text$
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .pipe(tap(() => this.searching = true))
      .pipe(switchMap(term => {

      const obs$ = this.http.get(ApiSettings.BASE_URL +`/company_report/${this.id}/company_report_files?q:label=${term}&page=1&type=report&order_by=updated_at`)
      return obs$.pipe(map((entities:any) => {
        this.totalResult = entities.total;
        this.showing = entities.data.length;
        return entities.data;
      }))
      .pipe(tap(() => {
        this.searchFailed = false;
        this.searching = false;
      }))
      .pipe(catchError(() => {
        this.searchFailed = true;
        return observableOf([]);
      }));
  }))
  }
  entitySelected(model: any) {
    if (model && model.id !== undefined) {
      this.tokenService.getToken().
      pipe(take(1)).subscribe(token => {
        const previewUrl = ApiSettings.BASE_URL + '/file/preview/' + model.source + '?token=' + token.token;
        this.onSelect.emit(previewUrl);
        this.model = '';
        this.searchInput.nativeElement.value = '';
      });

    }
  }
}
