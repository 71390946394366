import {Component, OnInit, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CompanyReportsSettings } from 'app/settings.class';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { EntitiesService } from 'app/entities/services/entities.service';
import * as moment from 'moment';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';

@Component({
  selector: 'con-income-statement',
  templateUrl: './income-statement.component.html'
})
export class IncomeStatementComponent implements OnInit, OnDestroy {
  @Input() entity: any = {};
  public loading = true;
  public routeSubscription: Subscription;
  public companyReport: any;
  public id: number;
  public noDataMessage = 'No statements to display';
  public entityCompanyReport: any;
  public resetKey = 'ISNew';
  public previousYearValues: any = {};
  public previousValueSource: any = {};
  public MLSendStatus = MLSendStatus;
  public previewUrl: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public entitiesService: EntitiesService,
    public companyService: CompanyReportsService
  ) { }

  ngOnInit() {
    this.entityCompanyReport = {
      company_report: {
        company: {
          name:
            this.entity.company.name +
            ' - ' +
            moment(this.entity.report_date).format('yyyy-MM-DD'),
        },
        id: this.entity.id,
      },
      company_report_id: this.entity.id,
    };
    this.companyService.selectedReport.subscribe(entity => {
      this.companyReport.data.forEach((income) => {
        income.locked = entity.locked;
        if (income.company_report) {
          income.company_report = entity.locked;
        }
      });
    });
    this.routeSubscription = this.route.params.subscribe(
      (params: Params) => {
        this.id = params.id;
      }
    );
    this.loadPage();
  }
  loadPage() {
    this.loading = true;
    this.http
      .get(
        CompanyReportsSettings.BASE_URL + '/' + this.id + '/income_statements'
      )
      .subscribe(
        (companyReport) => {
          this.companyReport = companyReport;
          if (this.companyReport.data.length) {
            this.companyReport.data.forEach((income, index) => {
              if (index === 0) {
                this.getIncomeSatementData(income);
              } else {
                income.hide = true;
              }
            });
          }
          this.loading = false;
        },
        (err) => {
          if (err.status === 404) {
            this.router.navigate(['income-statement', 'error'], {
              skipLocationChange: true,
            });
          }
          this.loading = false;
        }
      );
  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
  public toggleType(event?) {
    event.hide = !event.hide;
  }
  getDisplayName(income?: any) {
    let displayName = this.entity.company.name;
    if (income) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD') +
        '/ Income statement (' +
        moment(income.from_date).format('yyyy-MM-DD') +
        ' - ' +
        moment(income.to_date).format('yyyy-MM-DD') +
        ' )';
    }
    return displayName;
  }
  getDisplayTitle() {
    let displayName = this.entity.company.name;
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  getMutations(event) {
    return !event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
  }
  incomeStatementCreated(incomeStatement) {
    this.companyReport.data.unshift(incomeStatement);
    this.getIncomeSatementData(incomeStatement);
    this.entitiesService.resetDayInput.next(this.resetKey)
  }
  public getIncomeSatementData(income: any) {
    if (income && income.incomeData) {
      return;
    }
    income.loading = true;
    this.http
      .get(
        CompanyReportsSettings.BASE_URL +
        '/' +
        'income_statement' +
        '/' +
        income.id
      )
      .subscribe(
        (statement) => {
          this.previousYearValues = {};
          this.previousValueSource = {};
          this.companyService.formatPreviousYearData(statement,this.previousYearValues,this.previousValueSource);
          income.incomeData = statement;
          income.loading = false;
        },
        (err) => {
          if (err.status === 404) {
            this.router.navigate(['income-statement', 'error'], {
              skipLocationChange: true,
            });
          }
          income.loading = false;
        }
      );
  }
  formatPreviousYearData(data: any) {
    this.previousYearValues = {};
    this.previousValueSource = {};
    if (data.past_modules && data.past_modules.length > 1) {
      this.formatPreviousYearValue(data.past_modules);
      this.setPreviousYearValue(data.modules);
      data.showPrevious = true;
    } else {
      data.showPrevious = false;
    }
  }
  formatPreviousYearValue(past_moduleList) {
    for (let index = 0; index < past_moduleList.length; index++) {
        this.previousYearValues[past_moduleList[index].module_id] = past_moduleList[index].value_rep;
        this.previousValueSource[past_moduleList[index].module_id] =  past_moduleList[index].value_source;
        if (past_moduleList[index].children && past_moduleList[index].children.length > 0) {
          this.formatPreviousYearValue(past_moduleList[index].children)
        }
    }
  }
  setPreviousYearValue(moduleList) {
    for (let index = 0; index < moduleList.length; index++) {
        moduleList[index].prev_year_value = this.previousYearValues[moduleList[index].module_id];
        moduleList[index].prev_value_source = this.previousValueSource[moduleList[index].module_id];
        if (moduleList[index].children && moduleList[index].children.length > 0) {
          this.setPreviousYearValue(moduleList[index].children)
        }
    }
  }
  getISTitle(incomeData: any) {
    if (!incomeData) {
      return;
    }
    let title = '';
    if (incomeData && incomeData.quantity && incomeData.quantity.name) {
      title = title + incomeData.quantity.name;
    }
    if (this.entity && this.entity.currency && this.entity.currency.name) {
      title = title + ', ' + this.entity.currency.name;
    }
    if (incomeData && incomeData.unit && incomeData.unit.name) {
      title = title +  ', (' + incomeData.unit.name + ')';
    }
    return title;
  }
  incomeStatementUpdated(incomeStatement, index) {
    this.companyReport.data[index] = incomeStatement;
    this.getIncomeSatementData(this.companyReport.data[index]);
  }
  get settingLock () {
    return this.companyService.locking;
  }

  appendPreviewUrl(data: any, incomeStatement) {
    incomeStatement.previewUrl = data;
  }
  setPreviewMLReportUrlEmpty(incomeStatement, closeEvent: boolean) {
    if(closeEvent) {
      incomeStatement.previewUrl = null;
    }
  }
}
