<div class="con-date-picker mb-1" [formGroup]="formDate" *ngIf="editing">
    <div class="con-date-picker-icon">
        <con-icon-by-name iconName="calendar" [mutations]="['fw']"></con-icon-by-name>
    </div>
    <div class="con-date-picker-input">
        <input class="form-control" type="number" formControlName="year" maxlength="4" placeholder="YYYY"  min="0" (keyup)="markControlAsDirty()">
    </div>
    <div class="con-date-picker-spacer">
        -
    </div>
    <div class="con-date-picker-input">
        <select class="form-control custom-select"  formControlName="month" (click)="markControlAsDirty()">
            <option *ngFor="let month of availableMonths"
                    [value]="month">{{ month }}
            </option>
        </select>
    </div>
    <div class="con-date-picker-spacer">
        -
    </div>
    <div class="con-date-picker-input">
        <input class="form-control" type="number" formControlName="date" maxlength="2" placeholder="DD" min="1"  (keyup)="markControlAsDirty()">
    </div>
</div>

<div class="con-time-picker mb-1" [formGroup]="formDate" *ngIf="editing">
    <div class="con-time-picker-icon">
        <con-icon-by-name iconName="clock-o" [mutations]="['fw']"></con-icon-by-name>
    </div>
    <div class="con-time-picker-input">
        <input class="form-control" type="number" formControlName="hour" maxlength="2" placeholder="HH" min="0" (keyup)="markControlAsDirty()">
    </div>
    <div class="con-time-picker-spacer">
        :
    </div>
    <div class="con-time-picker-input">
        <input class="form-control" type="number" formControlName="minute" maxlength="2" placeholder="MM" min="0" (keyup)="markControlAsDirty()">
    </div>
    <div class="con-time-picker-spacer">
        :
    </div>
    <div class="con-time-picker-input">
            <input class="form-control" type="number" formControlName="second" maxlength="2" placeholder="SS" min="0" (keyup)="markControlAsDirty()">
    </div>
    <div class="con-time-picker-spacer"></div>
    <ng-select class="date-picker" [clearable]="false" [items]="availableTimezones" formControlName="timezone" (ngModelChange)="markControlAsDirty()"></ng-select>
</div>
<div class="con-date-time-editor mb-2" *ngIf="editing && isToDate()">
    <div class="btn btn-sm btn-primary" (click)="setDate(1,'h')" placement="top" ngbTooltip="Add 1 hour">
    <i class="fa fa-plus"></i> 1H
    </div>
    <div class="btn btn-sm btn-primary" (click)="setDate(86399,'s')" placement="top" ngbTooltip="Add 1 day">
        <i class="fa fa-plus"></i> 1D
    </div>
</div>
<div class="con-date-time-editor">
    <div class="btn btn-sm btn-info" (click)="toggleEdit()" placement="top" ngbTooltip="Edit Date/Time">
        <con-icon-by-name iconName="pencil"></con-icon-by-name>
    </div>
    <div class="btn btn-sm btn-warning" (click)="reset()" placement="top" ngbTooltip="Remove Date/Time">
        <con-icon-by-name iconName="remove"></con-icon-by-name>
    </div>
    <con-date-shower *ngIf="dateSelected()" [date]="control.value"></con-date-shower>
    <span class="badge badge-light badge-time" *ngIf="!dateSelected()">No date selected</span>
</div>
