import { RouterModule, Routes } from '@angular/router';

import { StartComponent } from './components/layout/start.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CompanyComponent } from './components/layout/company.component';
import { InstitutionComponent } from './components/layout/institution.component';

export const calendarRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: StartComponent
            },
            {
                path: 'company/:id',
                component: CompanyComponent
            },
            {
                path: 'institution/:id',
                component: InstitutionComponent
            }
        ]
    }
];

export const calendarRouting = RouterModule.forChild(calendarRoutes);
