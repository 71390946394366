import { Injectable } from '@angular/core';

import { DEFAULT_DATE_FORMAT } from '../../settings.class';

import * as moment from 'moment-timezone';

@Injectable()
export class DateConverterService {
    private currentTimezone = moment.tz.guess();

    public fromEntityString(date: string, timeZone?) {
        if (timeZone) {
            return moment.utc(date)
                     .tz(timeZone);
        }
            return moment.utc(date)
                    .tz(this.currentTimezone);
    }
    public toEntityString(date: any) {
        return date.utc()
                   .format('yyyy-MM-DDTHH:mm:ssZ');
    }
}
