<con-loader *ngIf="loading"></con-loader>
<div *ngIf="!loading">
<con-new-report-file
    *ngIf="relation === 'report-overview'"
    [entity]="entity">
</con-new-report-file>
<con-report-shareholder
    *ngIf="relation === 'shareholders'"
    [entity]="entity">
</con-report-shareholder>
<con-report-employee
    *ngIf="relation === 'employees'"
    [entity]="entity">
</con-report-employee>
<con-income-statement
    *ngIf="relation === 'income-statement'"
    [entity]="entity">
</con-income-statement>
<con-balance-sheet
    *ngIf="relation === 'balance-sheet'"
    [entity]="entity">
</con-balance-sheet>
<con-cash-flow
    *ngIf="relation === 'cash-flow'"
    [entity]="entity">
</con-cash-flow>
<con-period-kpi
    *ngIf="relation === 'period_kpi'"
    [entity]="entity">
</con-period-kpi>
<con-snapshot-kpi
    *ngIf="relation === 'snapshot_kpi'"
    [entity]="entity">
</con-snapshot-kpi>
<con-static-company
    *ngIf="relation === 'static'"
    [entity]="entity.company">
</con-static-company>
<con-key-financials
    *ngIf="relation === 'key-financials'"
    [entity]="entity">
</con-key-financials>
</div>
