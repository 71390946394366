
import {switchMap, tap} from 'rxjs/operators';
import { Component, Input, OnChanges } from '@angular/core';

import { UsersService } from '../services/users.service';

import { Subject } from 'rxjs';

@Component({
    selector: 'con-avatar',
    templateUrl: './avatar.component.html'
})

export class AvatarComponent implements OnChanges {
    @Input() userId: number;
    @Input() size: number;
    @Input() showTooltip = true;

    private userId$: Subject<number> = new Subject<number>();
    public user: any;
    private loading = true;
    constructor(private users: UsersService) {
        this.userId$.pipe(
            tap(() => this.loading = true),
            switchMap(user_id => {
                return this.users.getUserById(user_id)
            }), )
            .subscribe(user => {
                this.user = user;
                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    ngOnChanges() {
        this.userId$.next(this.userId);
    }
}
